(function () {
    'use strict';

    angular
        .module('fliApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('fli-person-detail', {
                parent: 'app',
                url: '/c/:centreId/p/:personId/detail',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/fli/person-detail/person-detail.html',
                        controller: 'FliPersonDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fli');
                        $translatePartialLoader.addPart('fliPerson');
                        $translatePartialLoader.addPart('fliSession');
                        $translatePartialLoader.addPart('hearingType');
                        $translatePartialLoader.addPart('person');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FliPerson', function ($stateParams, FliPerson) {
                        console.log("$stateParams", $stateParams);
                        return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('fli-person-detail.edit', {
                parent: 'fli-person-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_CENTRE', 'ROLE_PERSON', 'ROLE_CENTRE_SUPERVISOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/fli/person-detail/person-dialog.html',
                        controller: 'FliPersonDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['FliPerson', function (FliPerson) {
                                console.log("$stateParams", $stateParams);
                                return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                            }],
                            parents: ['$stateParams', 'CentreUsers', function($stateParams, CentreUsers) {
                                console.log("$stateParams", $stateParams);
                                return CentreUsers.query({centreId: $stateParams.centreId});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('fli-person-detail.editDetails', {
                parent: 'fli-person-detail',
                url: '/:category/:profileCategoryId',
                data: {
                    authorities: ['ROLE_CENTRE', 'ROLE_PERSON', 'ROLE_CENTRE_SUPERVISOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'PersonDetailUtils', function ($stateParams, $state, $uibModal, PersonDetailUtils) {
                    console.log("open FliPersonDialogController");
                    $uibModal.open({
                        // templateUrl: 'app/fli/person-detail/person-dialog-devices.html',
                        templateUrl: 'app/fli/person-detail/' + PersonDetailUtils.template($stateParams.category),
                        controller: 'FliPersonDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['FliPerson', function (FliPerson) {
                                console.log("$stateParams", $stateParams);
                                return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                            }],
                            parents: ['$stateParams', 'CentreUsers', function($stateParams, CentreUsers) {
                                console.log("$stateParams", $stateParams);
                                return CentreUsers.query({centreId: $stateParams.centreId});
                                // return null;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('fli-person-detail.delete', {
                parent: 'fli-person-detail',
                url: '/delete/:category/:profileCategoryId',
                data: {
                    authorities: ['ROLE_CENTRE', 'ROLE_PERSON', 'ROLE_CENTRE_SUPERVISOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    console.log("open FliPersonDetailDeleteDialogController");
                    console.log("$stateParams", $stateParams);

                    $uibModal.open({
                        templateUrl: 'app/fli/person-detail/person-detail-delete-dialog.html',
                        controller: 'FliPersonDetailDeleteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['FliPerson', function (FliPerson) {
                                console.log("$stateParams", $stateParams);
                                return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        console.log("FliPersonDetailDeleteDialogController then");
                        $state.go('fli-person-detail', null, {reload: true});
                    }, function () {
                        console.log("FliPersonDetailDeleteDialogController go");
                        $state.go('^');
                    });
                }]
            })
        ;
    }
})();

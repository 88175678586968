(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliSessionDialogController', FliSessionDialogController);

    FliSessionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DateUtils', 'session', 'person', 'SessionUtils', 'PersonSessionCurrent', 'uibDateParser', 'Principal'];

    function FliSessionDialogController($timeout, $scope, $stateParams, $uibModalInstance, DateUtils, session, person, SessionUtils, PersonSessionCurrent, uibDateParser, Principal) {
        var vm = this;

        vm.currentAccount = null;
        vm.preferredSessionType = null;
        vm.preferredLastApplication = null;
        vm.preferredSupportedHearingType = null;
        vm.person = person;
        vm.session = session;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.datePickerOptions = {
            showWeeks: false,
            minDate: new Date(),  // This gets set to the last session
            maxDate: new Date()
        };
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.toggleCheckbox = toggleCheckbox;
        vm.newSession = false;
        vm.dateformat = DateUtils.dateformat();

        console.log("session", session);
        console.log("person", person);
        console.log("$stateParams", $stateParams);

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            vm.preferredSessionType = Principal.preferredSessionType();
            vm.preferredSupportedHearingType = Principal.preferredSupportedHearingType();
            vm.preferredLastApplication = Principal.preferredLastApplication();
        });

        loadAll();

        function loadAll() {
            console.log("loadAll");
            console.log("loadAll $stateParams", $stateParams);

            PersonSessionCurrent.get({
                personId: $stateParams.personId,
                centreId: $stateParams.centreId
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.session = data;

                vm.session.date = new Date(); // default to today

                // Don't allow sessions before DOB or before the last session
                var lastSession = uibDateParser.parse(vm.session.latestDate, 'yyyy-MM-dd');
                var dob = uibDateParser.parse(vm.person.dob, 'yyyy-MM-dd');
                var minDate = dob;
                if (lastSession && lastSession > dob) {
                    minDate = lastSession;
                }
                console.log(">>> lastSession:", lastSession, " dob:", dob, "minDate:", minDate, "vm.session.date", vm.session.date);
                vm.datePickerOptions.minDate = minDate;


                console.log("loadAll", vm.session);
                vm.observations = SessionUtils.sortSessionData(data);

                console.log("$stateParams.sessionId", $stateParams.sessionId);
                if (!$stateParams.sessionId || $stateParams.sessionId == "") {
                    vm.session.id = null;
                    vm.session.latestInterpreter = false;
                    console.log("new session", vm.session);
                    vm.newSession = true;
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function toggleCheckbox(observation) {
            console.log("toggleCheckbox", observation);
            if (!observation.observedBy) {
                observation.observedBy = 'OBSERVED_BY_CLINICIAN';
                observation.observedBy = 'OBSERVED_BY_PARENT';
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            console.log('save', vm.session);
            // vm.person.centreId = $stateParams.centreId;
            // console.log('save', vm.person);
            console.log('$stateParams', $stateParams);

            vm.isSaving = true;
            if (vm.session.id !== null) {
                console.log('update');
                PersonSessionCurrent.update({centreId: $stateParams.centreId, personId: $stateParams.personId}, vm.session, onSaveSuccess, onSaveError);
            } else {
                console.log('save');
                PersonSessionCurrent.save({centreId: $stateParams.centreId, personId: $stateParams.personId}, vm.session, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            console.log('emit fliApp:personSessionUpdate', result);
            $scope.$emit('fliApp:personSessionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliController', FliController);

    FliController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'AlertService', 'DateUtils', 'entity', 'FliScoreNormative', 'FliScorePerson', 'TabControl', 'Principal'];

    function FliController($scope, $rootScope, $state, $stateParams, previousState, AlertService, DateUtils, entity, FliScoreNormative, FliScorePerson, TabControl, Principal) {
        var vm = this;

        console.log("$state", $state);
        console.log("entity", entity);
        console.log("$stateParams", $stateParams);

        vm.currentAccount = null;
        vm.preferredSessionType = null;
        vm.preferredLastApplication = null;
        vm.preferredSupportedHearingType = null;
        vm.person = entity;
        vm.previousState = previousState.name;
        vm.data = {};
        vm.personScore = {};
        vm.dateformat = DateUtils.dateformat();

        vm.tabset = TabControl;
        vm.tabset.setTab(2);

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            vm.preferredSessionType = Principal.preferredSessionType();
            vm.preferredLastApplication = Principal.preferredLastApplication();
            vm.preferredSupportedHearingType = Principal.preferredSupportedHearingType();
        });

        loadAll();

        function loadAll() {
            console.log("loadAll");
            console.log("loadAll $stateParams", $stateParams);
            console.log("loadAll $state", $state);

            FliScoreNormative.get({}, onSuccess, onError);

            function onSuccess(data, headers) {
                console.log("loadAll", data);
                vm.data = data;
                loadPerson();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

            // var chart = d3.select(".chart");

            // var data = [4, 8, 15, 16, 23, 42];
            // d3.select(".chart")
            //   .selectAll("div")
            //     .data(data)
            //   .enter().append("div")
            //     .style("width", function(d) { return d * 10 + "px"; })
            //     .text(function(d) { return d; });

        }

        function loadPerson() {
            console.log("loadPerson");
            console.log("loadPerson $stateParams", $stateParams);
            console.log("loadPerson $state", $state);

            FliScorePerson.get({
                personId: $stateParams.personId,
                centreId: $stateParams.centreId
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                console.log("loadPerson", data);
                vm.personScore = data;
                buildScatterPlot();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        // buildScatterPlot();
        function buildScatterPlot() {
            var margin = {top: 20, right: 20, bottom: 30, left: 40},
                width = 860 - margin.left - margin.right,
                height = 500 - margin.top - margin.bottom;

            var x = d3.scale.linear().range([0, width]);

            var y = d3.scale.linear().range([height, 0]);

            var color = d3.scale.category20c();

            var xAxis = d3.svg.axis()
                .scale(x)
                .orient("bottom");

            var yAxis = d3.svg.axis()
                .scale(y)
                .orient("left");

            // var svg = d3.select("body").append("svg")
            // var svg = d3.select(".chart").selectAll("div").append("svg")
            var svg = d3.select(".chart").append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            var csvData = [
                {"age": 1, "score": 5, "count": 1},
                {"age": 3, "score": 6, "count": 2},
                {"age": 3, "score": 7, "count": 1},
                {"age": 13, "score": 9, "count": 2},
                {"age": 30, "score": 14, "count": 1}
            ];

            var personData = [
                {"age": 1, "score": 1},
                {"age": 3, "score": 3},
                {"age": 6, "score": 5},
                {"age": 9, "score": 9},
                {"age": 12, "score": 8},
                {"age": 15, "score": 18},
                {"age": 18, "score": 19},
                {"age": 21, "score": 22}
            ];
            personData = vm.personScore.personScores;
            var personAge = vm.personScore.age;

            var averageData = [
                {"age": 1, "score": 1},
                {"age": 3, "score": 3},
                {"age": 6, "score": 5},
                {"age": 9, "score": 9},
                {"age": 12, "score": 8},
                {"age": 15, "score": 18},
                {"age": 18, "score": 19},
                {"age": 21, "score": 22}
            ];
            averageData = vm.data.averageScores;

            console.log("averageData:", averageData);
            // data = vm.data.normativeScores;
            // data.forEach(function (d) {
            //     d.score = +d.score;
            //     d.age = +d.age;
            //     d.count = +d.count;
            // });


            var dobLine = d3.svg.line()
                .x(function (d) {
                    return x(d.age);
                })
                .y(function (d) {
                    return y(d.score);
                });

            var line = d3.svg.line()
                .x(function (d) {
                    return x(d.age);
                })
                .y(function (d) {
                    return y(d.score);
                });

            // https://github.com/d3/d3-3.x-api-reference/blob/master/SVG-Shapes.md
            // http://www.d3noob.org/2013/01/smoothing-out-lines-in-d3js.html
            var smooth = d3.svg.line()
                .x(function (d) {
                    return x(d.age);
                })
                .y(function (d) {
                    return y(d.score);
                })
                // .tension(0.9)
                .interpolate("basis");
            // .interpolate("basis-open");
            // .interpolate("bundle");
            // .interpolate("linear");
            // .interpolate("basis");

            var basis = d3.svg.line()
                .x(function (d) {
                    return x(d.age);
                })
                .y(function (d) {
                    return y(d.score);
                })
                .interpolate("basis");

            var linear = d3.svg.line()
                .x(function (d) {
                    return x(d.age);
                })
                .y(function (d) {
                    return y(d.score);
                })
                .interpolate("linear");


            // d3.tsv("data.tsv", function (error, data) {
            d3.csv(csvData, function (error, data) {
                console.log("error:", error);

                if (error) throw error;

                // console.log("csvData:", csvData);
                // data = csvData;
                // data.forEach(function (d) {
                //     console.log("csvData d:", d);
                //     d.score = +d.score;
                //     d.age = +d.age;
                //     d.count = +d.count;
                // });

                console.log("plot", vm.data);
                data = vm.data.normativeScores;
                data.forEach(function (d) {
                    d.score = +d.score;
                    d.age = +d.age;
                    d.count = +d.count;
                });

                averageData = vm.data.averageScores;
                console.log("averageData:", averageData);

                x.domain(d3.extent(data, function (d) {
                    return d.age;
                })).nice();

                y.domain(d3.extent(data, function (d) {
                    return d.score;
                })).nice();

                x.domain([0, 84]);
                y.domain([0, 60]);

                svg.append("g")
                    .attr("class", "x axis")
                    .attr("transform", "translate(0," + height + ")")
                    .call(xAxis)
                    .append("text")
                    .attr("class", "label")
                    .attr("x", width)
                    .attr("y", -6)
                    .style("text-anchor", "end")
                    .text("Age (months)");

                svg.append("g")
                    .attr("class", "y axis")
                    .call(yAxis)
                    .append("text")
                    .attr("class", "label")
                    .attr("transform", "rotate(-90)")
                    .attr("y", 6)
                    .attr("dy", ".71em")
                    .style("text-anchor", "end")
                    .text("FLI Score");

                svg.selectAll(".dot")
                    .data(data)
                    .enter().append("circle")
                    .attr("class", "dot")
                    // .attr("r", 3.5)
                    .attr("r", function (d) {
                        return d.count * 2;
                    })
                    .attr("cx", function (d) {
                        return x(d.age);
                    })
                    .attr("cy", function (d) {
                        return y(d.score);
                    });
                // .style("stroke-width", function (d) {
                //     return 0.5;
                // })
                // .style("fill", function (d) {
                //     // return color(d.species);
                //     return "#eeeeee";
                //     // return color();
                // });

                
                if (personData.length == 1) {
                    svg.selectAll(".singleDot")
                        .data(personData)
                        .enter().append("circle")
                        .attr("class", "singleDot")
                        .attr("r", 5)
                        .attr("cx", function (d) {
                            return x(d.age);
                        })
                        .attr("cy", function (d) {
                            return y(d.score);
                        });
                } else {
                    var startPoint = [];
                    startPoint.push(personData[0]);
                    svg.selectAll(".startPoint")
                        .data(startPoint)
                        .enter().append("circle")
                        .attr("class", "singleDot")
                        .attr("r", 2)
                        .attr("cx", function (d) {
                            return x(d.age);
                        })
                        .attr("cy", function (d) {
                            return y(d.score);
                        });

                    var endPoint = [];
                    endPoint.push(personData[personData.length - 1]);
                    svg.selectAll(".endPoint")
                        .data(endPoint)
                        .enter().append("circle")
                        .attr("class", "singleDot")
                        .attr("r", 2)
                        .attr("cx", function (d) {
                            return x(d.age);
                        })
                        .attr("cy", function (d) {
                            return y(d.score);
                        });

                    svg.append("path")
                        .datum(personData)
                        .attr("class", "line")
                        .attr("d", line)
                        .style("stroke", "#79b0ff");
                    // .style("stroke", "#6dff82");
                }


                // svg.append("path")
                //     .datum(averageData)
                //     .attr("class", "line")
                //     .attr("d", smooth)
                //     .style("stroke", "#79b0ff");

                // svg.append("path")
                //     .datum(averageData)
                //     .attr("class", "line")
                //     .attr("d", linear)
                //     .style("stroke", "#ff9c95");

                var dob = [
                    {"age": personAge, "score": 0},
                    {"age": personAge, "score": 60}
                ];
                svg.append("path")
                    .datum(dob)
                    .attr("class", "dobline")
                    .attr("d", dobLine);

                // var legend = svg.selectAll(".legend")
                //     .data(color.domain())
                //     .enter().append("g")
                //     .attr("class", "legend")
                //     .attr("transform", function (d, i) {
                //         return "translate(0," + i * 20 + ")";
                //     });
                //
                // legend.append("rect")
                //     .attr("x", width - 18)
                //     .attr("width", 18)
                //     .attr("height", 18)
                //     .style("fill", color);
                //
                // legend.append("text")
                //     .attr("x", width - 24)
                //     .attr("y", 9)
                //     .attr("dy", ".35em")
                //     .style("text-anchor", "end")
                //     .text(function (d) {
                //         return d;
                //     });

            });
        }

        // buildBoxChart();
        function buildBoxChart() {
            var margin = {top: 10, right: 50, bottom: 20, left: 50},
                width = 120 - margin.left - margin.right,
                height = 500 - margin.top - margin.bottom;

            var min = Infinity,
                max = -Infinity;

            var chart = d3.box()
                .whiskers(iqr(1.5))
                .width(width)
                .height(height);


            //var morley = "Expt,Run,Speed
            //1,1,850
            //1,2,740
            //1,3,900";

            var csvData = [
                {"Expt": 1, "Run": 1, "Speed": 850},
                {"Expt": 1, "Run": 2, "Speed": 640},
                {"Expt": 2, "Run": 1, "Speed": 540},
                {"Expt": 2, "Run": 2, "Speed": 640},
                {"Expt": 2, "Run": 3, "Speed": 740},
                {"Expt": 3, "Run": 1, "Speed": 740},
                {"Expt": 3, "Run": 2, "Speed": 640},
                {"Expt": 3, "Run": 3, "Speed": 540}
            ];

            //d3.csv("morley.csv", function(error, csv) {
            //d3.csv("/api/score/normative", function(error, csv) {
            d3.csv(csvData, function (error, csv) {
                if (error) throw error;

                console.log("csvData:", csvData);
                var data = [];
                //  var data = [4, 8, 15, 16, 23, 42];


                csvData.forEach(function (x) {
                    var e = Math.floor(x.Expt - 1),
                        r = Math.floor(x.Run - 1),
                        s = Math.floor(x.Speed),
                        d = data[e];
                    if (!d) d = data[e] = [s];
                    else d.push(s);
                    if (s > max) max = s;
                    if (s < min) min = s;
                });
                console.log("max:", max);
                console.log("min:", min);
                console.log("data:", data);


                chart.domain([min, max]);

                //  var svg = d3.select("body").selectAll("svg")

                var svg = d3.select(".chart").selectAll("div")
                    .data(data)
                    .enter().append("svg")
                    .attr("class", "box")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.bottom + margin.top)
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                    .call(chart);

                //        setInterval(function () {
                //            svg.datum(randomize).call(chart.duration(1000));
                //        }, 2000);
            });

            function randomize(d) {
                if (!d.randomizer) d.randomizer = randomizer(d);
                return d.map(d.randomizer);
            }

            function randomizer(d) {
                var k = d3.max(d) * .02;
                return function (d) {
                    return Math.max(min, Math.min(max, d + k * (Math.random() - .5)));
                };
            }

            // Returns a function to compute the interquartile range.
            function iqr(k) {
                return function (d, i) {
                    var q1 = d.quartiles[0],
                        q3 = d.quartiles[2],
                        iqr = (q3 - q1) * k,
                        i = -1,
                        j = d.length;
                    while (d[++i] < q1 - iqr);
                    while (d[--j] > q3 + iqr);
                    return [i, j];
                };
            }

        }


    }

    d3.box = function () {
        var width = 1,
            height = 1,
            duration = 0,
            domain = null,
            value = Number,
            whiskers = boxWhiskers,
            quartiles = boxQuartiles,
            tickFormat = null;

        // For each small multiple…
        function box(g) {
            g.each(function (d, i) {
                d = d.map(value).sort(d3.ascending);
                var g = d3.select(this),
                    n = d.length,
                    min = d[0],
                    max = d[n - 1];

                // Compute quartiles. Must return exactly 3 elements.
                var quartileData = d.quartiles = quartiles(d);

                // Compute whiskers. Must return exactly 2 elements, or null.
                var whiskerIndices = whiskers && whiskers.call(this, d, i),
                    whiskerData = whiskerIndices && whiskerIndices.map(function (i) {
                            return d[i];
                        });

                // Compute outliers. If no whiskers are specified, all data are "outliers".
                // We compute the outliers as indices, so that we can join across transitions!
                var outlierIndices = whiskerIndices
                    ? d3.range(0, whiskerIndices[0]).concat(d3.range(whiskerIndices[1] + 1, n))
                    : d3.range(n);

                // Compute the new x-scale.
                var x1 = d3.scale.linear()
                    .domain(domain && domain.call(this, d, i) || [min, max])
                    .range([height, 0]);

                // Retrieve the old x-scale, if this is an update.
                var x0 = this.__chart__ || d3.scale.linear()
                        .domain([0, Infinity])
                        .range(x1.range());

                // Stash the new scale.
                this.__chart__ = x1;

                // Note: the box, median, and box tick elements are fixed in number,
                // so we only have to handle enter and update. In contrast, the outliers
                // and other elements are variable, so we need to exit them! Variable
                // elements also fade in and out.

                // Update center line: the vertical line spanning the whiskers.
                var center = g.selectAll("line.center")
                    .data(whiskerData ? [whiskerData] : []);

                center.enter().insert("line", "rect")
                    .attr("class", "center")
                    .attr("x1", width / 2)
                    .attr("y1", function (d) {
                        return x0(d[0]);
                    })
                    .attr("x2", width / 2)
                    .attr("y2", function (d) {
                        return x0(d[1]);
                    })
                    .style("opacity", 1e-6)
                    .transition()
                    .duration(duration)
                    .style("opacity", 1)
                    .attr("y1", function (d) {
                        return x1(d[0]);
                    })
                    .attr("y2", function (d) {
                        return x1(d[1]);
                    });

                center.transition()
                    .duration(duration)
                    .style("opacity", 1)
                    .attr("y1", function (d) {
                        return x1(d[0]);
                    })
                    .attr("y2", function (d) {
                        return x1(d[1]);
                    });

                center.exit().transition()
                    .duration(duration)
                    .style("opacity", 1e-6)
                    .attr("y1", function (d) {
                        return x1(d[0]);
                    })
                    .attr("y2", function (d) {
                        return x1(d[1]);
                    })
                    .remove();

                // Update innerquartile box.
                var box = g.selectAll("rect.box")
                    .data([quartileData]);

                box.enter().append("rect")
                    .attr("class", "box")
                    .attr("x", 0)
                    .attr("y", function (d) {
                        return x0(d[2]);
                    })
                    .attr("width", width)
                    .attr("height", function (d) {
                        return x0(d[0]) - x0(d[2]);
                    })
                    .transition()
                    .duration(duration)
                    .attr("y", function (d) {
                        return x1(d[2]);
                    })
                    .attr("height", function (d) {
                        return x1(d[0]) - x1(d[2]);
                    });

                box.transition()
                    .duration(duration)
                    .attr("y", function (d) {
                        return x1(d[2]);
                    })
                    .attr("height", function (d) {
                        return x1(d[0]) - x1(d[2]);
                    });

                // Update median line.
                var medianLine = g.selectAll("line.median")
                    .data([quartileData[1]]);

                medianLine.enter().append("line")
                    .attr("class", "median")
                    .attr("x1", 0)
                    .attr("y1", x0)
                    .attr("x2", width)
                    .attr("y2", x0)
                    .transition()
                    .duration(duration)
                    .attr("y1", x1)
                    .attr("y2", x1);

                medianLine.transition()
                    .duration(duration)
                    .attr("y1", x1)
                    .attr("y2", x1);

                // Update whiskers.
                var whisker = g.selectAll("line.whisker")
                    .data(whiskerData || []);

                whisker.enter().insert("line", "circle, text")
                    .attr("class", "whisker")
                    .attr("x1", 0)
                    .attr("y1", x0)
                    .attr("x2", width)
                    .attr("y2", x0)
                    .style("opacity", 1e-6)
                    .transition()
                    .duration(duration)
                    .attr("y1", x1)
                    .attr("y2", x1)
                    .style("opacity", 1);

                whisker.transition()
                    .duration(duration)
                    .attr("y1", x1)
                    .attr("y2", x1)
                    .style("opacity", 1);

                whisker.exit().transition()
                    .duration(duration)
                    .attr("y1", x1)
                    .attr("y2", x1)
                    .style("opacity", 1e-6)
                    .remove();

                // Update outliers.
                var outlier = g.selectAll("circle.outlier")
                    .data(outlierIndices, Number);

                outlier.enter().insert("circle", "text")
                    .attr("class", "outlier")
                    .attr("r", 5)
                    .attr("cx", width / 2)
                    .attr("cy", function (i) {
                        return x0(d[i]);
                    })
                    .style("opacity", 1e-6)
                    .transition()
                    .duration(duration)
                    .attr("cy", function (i) {
                        return x1(d[i]);
                    })
                    .style("opacity", 1);

                outlier.transition()
                    .duration(duration)
                    .attr("cy", function (i) {
                        return x1(d[i]);
                    })
                    .style("opacity", 1);

                outlier.exit().transition()
                    .duration(duration)
                    .attr("cy", function (i) {
                        return x1(d[i]);
                    })
                    .style("opacity", 1e-6)
                    .remove();

                // Compute the tick format.
                var format = tickFormat || x1.tickFormat(8);

                // Update box ticks.
                var boxTick = g.selectAll("text.box")
                    .data(quartileData);

                boxTick.enter().append("text")
                    .attr("class", "box")
                    .attr("dy", ".3em")
                    .attr("dx", function (d, i) {
                        return i & 1 ? 6 : -6
                    })
                    .attr("x", function (d, i) {
                        return i & 1 ? width : 0
                    })
                    .attr("y", x0)
                    .attr("text-anchor", function (d, i) {
                        return i & 1 ? "start" : "end";
                    })
                    .text(format)
                    .transition()
                    .duration(duration)
                    .attr("y", x1);

                boxTick.transition()
                    .duration(duration)
                    .text(format)
                    .attr("y", x1);

                // Update whisker ticks. These are handled separately from the box
                // ticks because they may or may not exist, and we want don't want
                // to join box ticks pre-transition with whisker ticks post-.
                var whiskerTick = g.selectAll("text.whisker")
                    .data(whiskerData || []);

                whiskerTick.enter().append("text")
                    .attr("class", "whisker")
                    .attr("dy", ".3em")
                    .attr("dx", 6)
                    .attr("x", width)
                    .attr("y", x0)
                    .text(format)
                    .style("opacity", 1e-6)
                    .transition()
                    .duration(duration)
                    .attr("y", x1)
                    .style("opacity", 1);

                whiskerTick.transition()
                    .duration(duration)
                    .text(format)
                    .attr("y", x1)
                    .style("opacity", 1);

                whiskerTick.exit().transition()
                    .duration(duration)
                    .attr("y", x1)
                    .style("opacity", 1e-6)
                    .remove();
            });
            d3.timer.flush();
        }

        box.width = function (x) {
            if (!arguments.length) return width;
            width = x;
            return box;
        };

        box.height = function (x) {
            if (!arguments.length) return height;
            height = x;
            return box;
        };

        box.tickFormat = function (x) {
            if (!arguments.length) return tickFormat;
            tickFormat = x;
            return box;
        };

        box.duration = function (x) {
            if (!arguments.length) return duration;
            duration = x;
            return box;
        };

        // d3.functor = function functor(v) {
        //   return typeof v === "function" ? v : function() {
        //     return v;
        //   };
        // };

        box.domain = function (x) {
            if (!arguments.length) return domain;
            domain = x == null ? x : d3.functor(x);
            return box;
        };

        box.value = function (x) {
            if (!arguments.length) return value;
            value = x;
            return box;
        };

        box.whiskers = function (x) {
            if (!arguments.length) return whiskers;
            whiskers = x;
            return box;
        };

        box.quartiles = function (x) {
            if (!arguments.length) return quartiles;
            quartiles = x;
            return box;
        };

        return box;
    };

    function boxWhiskers(d) {
        return [0, d.length - 1];
    }

    function boxQuartiles(d) {
        return [
            d3.quantile(d, .25),
            d3.quantile(d, .5),
            d3.quantile(d, .75)
        ];
    }

})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('EventVersionController', EventVersionController);

    EventVersionController.$inject = ['$scope', '$state', 'EventVersion'];

    function EventVersionController ($scope, $state, EventVersion) {
        var vm = this;
        
        vm.eventVersions = [];

        loadAll();

        function loadAll() {
            EventVersion.query(function(result) {
                vm.eventVersions = result;
            });
        }
    }
})();

(function() {
    'use strict';
    angular
        .module('fliApp')
        .factory('FliScoreNormative', FliScoreNormative)
        .factory('FliScorePerson', FliScorePerson);

    FliScoreNormative.$inject = ['$resource', 'DateUtils'];
    FliScorePerson.$inject = ['$resource'];

    function FliScoreNormative ($resource, DateUtils) {
        var resourceUrl =  'api/score/normative';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function FliScorePerson ($resource) {
        var resourceUrl =  'api/score/centre/:centreId/person/:personId';

        return $resource(resourceUrl, {centreId: '@centreId', personId: '@personId'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

})();

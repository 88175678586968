(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliSessionController', FliSessionController);

    FliSessionController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'AlertService', 'DateUtils', 'entity', 'SessionUtils', 'PersonSessionCurrent', 'TabControl', 'Principal'];

    function FliSessionController($scope, $rootScope, $state, $stateParams, previousState, AlertService, DateUtils, entity, SessionUtils, PersonSessionCurrent, TabControl, Principal) {
        var vm = this;

        console.log("$state", $state);
        console.log("entity", entity);
        console.log("$stateParams", $stateParams);
        console.log("PersonSessionCurrent", PersonSessionCurrent);

        vm.currentAccount = null;
        vm.preferredSessionType = null;
        vm.preferredLastApplication = null;
        vm.preferredSupportedHearingType = null;
        vm.person = entity;
        vm.previousState = previousState.name;
        vm.hasSessions = false;
        vm.dateformat = DateUtils.dateformat();

        vm.tabset = TabControl;
        vm.tabset.setTab(3);
        // vm.tab = {'active' : true};

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            vm.preferredSessionType = Principal.preferredSessionType();
            vm.preferredSupportedHearingType = Principal.preferredSupportedHearingType();
            vm.preferredLastApplication = Principal.preferredLastApplication();
        });


        var unsubscribe = $rootScope.$on('fliApp:personSessionUpdate', function (event, result) {
            console.log("on fliApp:personSessionUpdate", result);
            loadAll();
        });
        $scope.$on('$destroy', unsubscribe);


        loadAll();

        function loadAll() {
            console.log("loadAll");
            console.log("loadAll $stateParams", $stateParams);
            console.log("loadAll $state", $state);

            PersonSessionCurrent.get({
                personId: $stateParams.personId,
                centreId: $stateParams.centreId
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.session = data;
                console.log("loadAll", vm.session);
                vm.observations = SessionUtils.sortSessionData(data);
                vm.hasSessions = vm.session.id != null;
                console.log("hasSessions", vm.hasSessions);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }

})();

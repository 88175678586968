(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('SuperManagementDetailController', SuperManagementDetailController);

    SuperManagementDetailController.$inject = ['$stateParams', 'SuperUser'];

    function SuperManagementDetailController ($stateParams, SuperUser) {
        var vm = this;

        vm.load = load;
        vm.user = {};

        vm.load($stateParams.login);

        function load (login) {
            SuperUser.get({login: login}, function(result) {
                vm.user = result;
            });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('fli', {
            parent: 'app',
            url: '/c/:centreId/p/:personId/fli',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/fli/fli/fli.html',
                    controller: 'FliController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fli');
                    $translatePartialLoader.addPart('fliPerson');
                    $translatePartialLoader.addPart('fliSession');
                    $translatePartialLoader.addPart('hearingType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'FliPerson', function ($stateParams, FliPerson) {
                    console.log("entity $stateParams", $stateParams);
                    return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'person',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();

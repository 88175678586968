(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliPersonDeleteDialogController',FliPersonDeleteDialogController);

    FliPersonDeleteDialogController.$inject = ['$uibModalInstance', '$stateParams', 'entity', 'FliPerson'];

    function FliPersonDeleteDialogController($uibModalInstance, $stateParams, entity, FliPerson) {
        var vm = this;

        console.log('entity', entity);
        console.log('$stateParams', $stateParams);

        vm.category = $stateParams.category;
        vm.person = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (centreId, personId) {
            console.log('confirmDelete centreId:', centreId, " personId:", personId);
            console.log('confirmDelete $stateParams:', $stateParams);

            // var resourceUrl = 'api/centre/:centreId/person/:personId';

//            return $resource(resourceUrl, {centreId: '@centreId', personId: '@personId'}, {

            FliPerson.delete({centreId: centreId, personId: personId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('PersonProfileDetailController', PersonProfileDetailController);

    PersonProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PersonProfile', 'Person'];

    function PersonProfileDetailController($scope, $rootScope, $stateParams, previousState, entity, PersonProfile, Person) {
        var vm = this;

        vm.personProfile = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('fliApp:personProfileUpdate', function(event, result) {
            vm.personProfile = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('centresuper-management', {
            parent: 'centre-super',
            url: '/centre-super-management?page&sort',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CENTRE_SUPERVISOR'],
                pageTitle: 'userManagement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/fli/centre-super-management/centre-super-management.html',
                    controller: 'CentreSuperManagementController',
                    controllerAs: 'vm'
                }
            },            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('userCentre');
                    $translatePartialLoader.addPart('userRole');
                    return $translate.refresh();
                }]

            }        })
        .state('centresuper-management-detail', {
            parent: 'centre-super',
            url: '/centre-super-management/:login',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CENTRE_SUPERVISOR'],
                pageTitle: 'user-management.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/fli/centre-super-management/centre-super-management-detail.html',
                    controller: 'CentreSuperManagementDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('userCentre');
                    $translatePartialLoader.addPart('userRole');
                    return $translate.refresh();
                }]
            }
        })
        .state('centresuper-management.new', {
            parent: 'centresuper-management',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CENTRE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/fli/centre-super-management/centre-super-management-dialog.html',
                    controller: 'CentreSuperManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null, login: null, firstName: null, lastName: null, email: null,
                                activated: true, langKey: null, createdBy: null, createdDate: null,
                                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                resetKey: null, authorities: null
                            };
                        },
                        centres: ['CentreSuperUserCentres', function(CentreSuperUserCentres) {
                            return CentreSuperUserCentres.query();
                        }]
                    }
                }).result.then(function() {
                    $state.go('centresuper-management', null, { reload: true });
                }, function() {
                    $state.go('centresuper-management');
                });
            }]
        })
        .state('centresuper-management.edit', {
            parent: 'centresuper-management',
            url: '/{login}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CENTRE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/fli/centre-super-management/centre-super-management-dialog.html',
                    controller: 'CentreSuperManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CentreSuperUser', function(CentreSuperUser) {
                            return CentreSuperUser.get({login : $stateParams.login});
                        }],
                        centres: ['CentreSuperUserCentres', function(CentreSuperUserCentres) {
                            return CentreSuperUserCentres.query();
                        }]
                    }
                }).result.then(function() {
                    $state.go('centresuper-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('centresuper-management.delete', {
            parent: 'centresuper-management',
            url: '/{login}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_CENTRE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/fli/centre-super-management/centre-super-management-delete-dialog.html',
                    controller: 'CentreSuperManagementDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CentreSuperUser', function(CentreSuperUser) {
                            return CentreSuperUser.get({login : $stateParams.login});
                        }]
                    }
                }).result.then(function() {
                    $state.go('centresuper-management', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();

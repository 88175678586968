(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('CentrePersonDialogController', CentrePersonDialogController);

    CentrePersonDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CentrePerson', 'Centre', 'Person'];

    function CentrePersonDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CentrePerson, Centre, Person) {
        var vm = this;

        vm.centrePerson = entity;
        vm.clear = clear;
        vm.save = save;
        vm.centres = Centre.query();
        vm.people = Person.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.centrePerson.id !== null) {
                CentrePerson.update(vm.centrePerson, onSaveSuccess, onSaveError);
            } else {
                CentrePerson.save(vm.centrePerson, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fliApp:centrePersonUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function () {
    'use strict';
    angular
        .module('fliApp')
        .factory('TabControl', TabControl);

    function TabControl() {

        var tab = {
            tabInfo: {
                tab: 0,
                groups: []
            },

            setTab: function (tabId) {
                tab.tabInfo.tab = tabId;
            },

            active: function (index) {
                if (index == tab.tabInfo.tab) {
                    return 'active';
                } else {
                    return '';
                }
            }
        };

        return tab;
    }

})();

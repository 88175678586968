(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('CentresController', CentresController);

    CentresController.$inject = ['$scope', '$state', 'Centres', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal'];

    function CentresController($scope, $state, Centres, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        var vm = this;

        vm.account = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.selectCentre = selectCentre;

        function selectCentre(userCentre) {
            console.log("selectCentre", userCentre);

            Centres.save(userCentre,
                function () {
                    console.log("success", userCentre);
                    // Force a reload of the users account when the centre changes
                    // This will also cause the page to refresh the data
                    loadAccount(true);
                    vm.error = null;
                    // vm.success = 'OK'; // TODO: Disable for now, as it doesn't seem to auto clear
                    vm.success = null;

                    // $state.go('home');
                    // $state.go('fli-person', {centreId: userCentre.centreId});

                },
                function () {
                    console.log("error");
                    vm.success = null;
                    vm.error = 'ERROR';
                });
        }

        function loadAccount(force) {
            Principal.identity(force).then(function (account) {
                vm.account = account;
                console.log("account", account);
                if (vm.account.userCentre) {
                    for (var i = 0; i < vm.account.userCentres.length; i++) {
                        var userCentre = vm.account.userCentres[i];
                        if (userCentre.id == vm.account.userCentre.id) {
                            userCentre.selected = true;
                        } else {
                            userCentre.selected = false;
                        }
                    }
                }
            });
        }

        loadAccount(false);

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();

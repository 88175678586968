(function() {
    'use strict';
    angular
        .module('fliApp')
        .factory('Centres', Centres);

    Centres.$inject = ['$resource'];

    function Centres ($resource) {
        var service = $resource('api/fliaccount/changecentre', {}, {});
        return service;
    }
})();

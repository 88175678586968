(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliPersonDetailController', FliPersonDetailController);

    FliPersonDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'DateUtils', 'entity', 'FliPerson', 'PersonDetailUtils', 'TabControl', 'Principal'];

    function FliPersonDetailController($scope, $rootScope, $state, $stateParams, previousState, DateUtils, entity, FliPerson, PersonDetailUtils, TabControl, Principal) {
        var vm = this;

        console.log("$state", $state);
        console.log("entity", entity);
        console.log("$stateParams", $stateParams);

        vm.category = PersonDetailUtils.categories();
        vm.countries = PersonDetailUtils.countries();
        vm.languages = PersonDetailUtils.languages();

        console.log("category", vm.category);
        vm.currentAccount = null;
        vm.preferredSessionType = null;
        vm.preferredLastApplication = null;
        vm.preferredSupportedHearingType = null;
        vm.person = entity;
        vm.previousState = previousState.name;
        vm.dateformat = DateUtils.dateformat();
        vm.showParent = false;

        vm.tabset = TabControl;
        vm.tabset.setTab(1);

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            vm.preferredSessionType = Principal.preferredSessionType();
            vm.preferredLastApplication = Principal.preferredLastApplication();
            vm.preferredSupportedHearingType = Principal.preferredSupportedHearingType();
            vm.showParent = vm.person.userActive && (vm.currentAccount.authorities.indexOf('ROLE_CENTRE') > -1 || vm.currentAccount.authorities.indexOf('ROLE_CENTRE_SUPERVISOR') > -1);
        });

        parsePerson();

        var unsubscribe = $rootScope.$on('fliApp:personUpdate', function (event, result) {
            vm.person = result;
            console.log("on fliApp:personUpdate", result);
            parsePerson();
        });
        $scope.$on('$destroy', unsubscribe);


        function parsePerson() {
            console.log("parsePerson", vm.person);

            vm.personIdentifier = PersonDetailUtils.identifier(vm.person.profileHolderDTO.profileCategories);
            vm.personHearingLoss = PersonDetailUtils.hearingLoss(vm.person.profileHolderDTO.profileCategories);
            vm.personHearingLossSide = PersonDetailUtils.hearingLossSide(vm.person.profileHolderDTO.profileCategories);
            vm.personDevices = PersonDetailUtils.devices(vm.person.profileHolderDTO.profileCategories);
            vm.personIntervention = PersonDetailUtils.intervention(vm.person.profileHolderDTO.profileCategories);

            console.log('vm.personIdentifier: ', vm.personIdentifier);
            console.log('vm.personHearingLoss: ', vm.personHearingLoss);
            console.log('vm.personHearingLossSide: ', vm.personHearingLossSide);
            console.log('vm.personDevices: ', vm.personDevices);
            console.log('vm.personIntervention: ', vm.personIntervention);

            if (vm.personIdentifier[0] && vm.personIdentifier[0].birthCountry) {
                vm.personCountry = vm.personIdentifier[0].birthCountry.value;
                console.log('vm.personCountry: ', vm.personCountry);
                for (var i in vm.countries) {
                    if (vm.countries[i]["key"] == vm.personIdentifier[0].birthCountry.value && vm.countries[i]["key"].length > 0) {
                        vm.personCountry = vm.personIdentifier[0].birthCountry.value + " (" + vm.countries[i]["value"] + ")";
                    }
                }
            }
            console.log('vm.personCountry: ', vm.personCountry);
            console.log('vm.languages: ', vm.languages);

            vm.personAdditionalSpokenLanguages = "";
            vm.personAdditionalLanguages = "";
            if (vm.personIdentifier[0]) {
                if (vm.personIdentifier[0].primaryLanguage) {
                    vm.personPrimaryLanguage = vm.personIdentifier[0].primaryLanguage.value;
                }
                var stripPersonAdditionalSpokenLanguagesComma = 0;
                var stripPersonAdditionalLanguagesComma = 0;
                for (var i in vm.languages) {
                    if (vm.languages[i]["key"] == vm.personIdentifier[0].primaryLanguage.value) {
                        vm.personPrimaryLanguage = vm.languages[i]["value"];
                    }
                    for (var j in vm.personIdentifier[0].additionalSpokenLanguages) {
                        if (vm.languages[i]["key"] == vm.personIdentifier[0].additionalSpokenLanguages[j].value && vm.languages[i]["key"].length > 0) {
                            vm.personAdditionalSpokenLanguages += vm.languages[i]['value'] + ", ";
                            stripPersonAdditionalSpokenLanguagesComma = 1;
                        }
                    }
                    for (var j in vm.personIdentifier[0].additionalLanguages) {
                        if (vm.languages[i]["key"] == vm.personIdentifier[0].additionalLanguages[j].value && vm.languages[i]["key"].length > 0) {
                            vm.personAdditionalLanguages += vm.languages[i]['value'] + ", ";
                            stripPersonAdditionalLanguagesComma = 1;
                        }
                    }
                }
                if (stripPersonAdditionalSpokenLanguagesComma) {
                    vm.personAdditionalSpokenLanguages = vm.personAdditionalSpokenLanguages.substr(0, vm.personAdditionalSpokenLanguages.length - 2)
                }
                if (stripPersonAdditionalLanguagesComma) {
                    vm.personAdditionalLanguages = vm.personAdditionalLanguages.substr(0, vm.personAdditionalLanguages.length - 2)
                }
            }
            console.log('vm.personPrimaryLanguage: ', vm.personPrimaryLanguage);
            console.log('vm.personAdditionalSpokenLanguages: ', vm.personAdditionalSpokenLanguages);
            console.log('vm.personAdditionalLanguages: ', vm.personAdditionalLanguages);
        }
    }


})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('PersonProfileDialogController', PersonProfileDialogController);

    PersonProfileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PersonProfile', 'Person'];

    function PersonProfileDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PersonProfile, Person) {
        var vm = this;

        vm.personProfile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.people = Person.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.personProfile.id !== null) {
                PersonProfile.update(vm.personProfile, onSaveSuccess, onSaveError);
            } else {
                PersonProfile.save(vm.personProfile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('fliApp:personProfileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliSessionHistoryController', FliSessionHistoryController);

    FliSessionHistoryController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'DateUtils', 'AlertService', 'entity', 'PersonSessions', 'TabControl', 'Principal'];

    function FliSessionHistoryController($scope, $rootScope, $state, $stateParams, previousState, DateUtils, AlertService, entity, PersonSessions, TabControl, Principal) {
        var vm = this;

        console.log("$state", $state);
        console.log("entity", entity);
        console.log("$stateParams", $stateParams);
        console.log("PersonSessions", PersonSessions);

        vm.currentAccount = null;
        vm.preferredSessionType = null;
        vm.preferredLastApplication = null;
        vm.preferredSupportedHearingType = null;
        vm.person = entity;
        vm.previousState = previousState.name;
        vm.dateformat = DateUtils.dateformat();

        vm.tabset = TabControl;
        vm.tabset.setTab(4);
        // vm.tab = {'active' : true};

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            vm.preferredSessionType = Principal.preferredSessionType();
            vm.preferredLastApplication = Principal.preferredLastApplication();
            vm.preferredSupportedHearingType = Principal.preferredSupportedHearingType();
        });

        var unsubscribe = $rootScope.$on('fliApp:personSessionUpdate', function (event, result) {
            // vm.person = result;
            console.log("on fliApp:personSessionUpdate", result);
            loadAll();
        });
        $scope.$on('$destroy', unsubscribe);

        // $('#myTabs a:last').tab('show');
        // $('#myTabs a').click(function (e) {
        //     console.log("click");
        //     e.preventDefault();
        //     $(this).tab('show');
        // });


        loadAll();

        function loadAll() {
            console.log("loadAll");
            console.log("loadAll $stateParams", $stateParams);
            console.log("loadAll $state", $state);

            PersonSessions.query({
                personId: $stateParams.personId,
                centreId: $stateParams.centreId
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                processData(data);
                console.log("loadAll", vm.sessions);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function processData(data) {
            vm.sessions = [];
            for (var i = 0; i < data.length; i++) {
                var obj = {};
                obj['session'] = data[i];
                var note = data[i]['sessionNote']['note'];

                var lines = 1;
                if (note) {
                    lines = note.split(/\r\n|\r|\n/).length;
                }
                if (lines > 5) {
                    lines = 5;
                }
                // if (lines < 2) {
                //     lines = 2;
                // }

                obj['lines'] = lines;
                vm.sessions.push(obj);
            }
        }
    }

})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliPersonDetailDeleteDialogController',FliPersonDetailDeleteDialogController);

    FliPersonDetailDeleteDialogController.$inject = ['$uibModalInstance', '$stateParams', 'entity', 'FliPersonProfile'];

    function FliPersonDetailDeleteDialogController($uibModalInstance, $stateParams, entity, FliPersonProfile) {
        var vm = this;

        console.log('entity', entity);
        console.log('$stateParams', $stateParams);

        vm.category = $stateParams.category;
        vm.person = entity;
        vm.profileCategoryId = $stateParams.profileCategoryId;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (centreId, personId, profileCategoryId) {
            console.log('confirmDelete centreId:', centreId, " personId:", personId, " profileCategoryId:", profileCategoryId);
            console.log('confirmDelete $stateParams:', $stateParams);

            // var resourceUrl = 'api/centre/:centreId/person/:personId';

//            return $resource(resourceUrl, {centreId: '@centreId', personId: '@personId'}, {

            FliPersonProfile.delete({centreId: centreId, personId: personId, profileCategoryId: profileCategoryId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .factory('CentreSuperUser', CentreSuperUser)
        .factory('CentreSuperUserCentres', CentreSuperUserCentres);

    CentreSuperUser.$inject = ['$resource'];
    CentreSuperUserCentres.$inject = ['$resource'];

    function CentreSuperUser ($resource) {
        var service = $resource('api/centresuperusers/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
        });

        return service;
    }

    function CentreSuperUserCentres ($resource) {
        var service = $resource('api/centresuperusers/centres', {}, {
            'query': {method: 'GET', isArray: true}
        });

        return service;
    }
})();

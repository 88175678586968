(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$rootScope', 'Principal', 'LoginService', '$state', 'Auth'];

    function HomeController($scope, $rootScope, Principal, LoginService, $state, Auth) {
        var vm = this;

        vm.centreId = Principal.centreId;
        vm.centre = Principal.centre;
        vm.hasCentre = Principal.hasCentre;
        vm.findChild = findChild;
        vm.addChild = addChild;
        vm.account = null;
        vm.isAuthenticated = null;
        // vm.login = LoginService.open;

        vm.login = login;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });

            if (Principal.hasCentre() && Principal.isAuthenticated()) {
                findChild();
            }
        }

        function register() {
            $state.go('register');
        }

        function findChild() {
            console.log('findChild');
            console.log('findChild centreId', vm.centreId());
            // $state.centreId = '222';
            // $state.go('fli-person');
            $state.go('fli-person', {centreId: vm.centreId()});
        }

        function addChild() {
            console.log('addChild');
            console.log('addChild centreId', vm.centreId());
            $state.go('fli-person.new', {centreId: vm.centreId()});
        }

        // Copied from login.controller
        //
        function login (event) {
            console.log("login");
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    // $state.go(previousState.name, previousState.params);
                }
                
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function requestResetPassword () {
            console.log("requestResetPassword");
            $state.go('requestReset');
        }

    }
})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('fli-person', {
                parent: 'entity',
                url: '/c/:centreId/p?page&sort&search',
                data: {
                    authorities: ['ROLE_CENTRE_SUPERVISOR', 'ROLE_CENTRE', 'ROLE_PERSON'],
                    pageTitle: 'fliApp.person.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/fli/person/people.html',
                        controller: 'FliPersonController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                    // centreId: '123'
                    // centreId: 'vm.centreId()'
                },
                resolve: {
                    // centreId: '456',
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fliPerson');
                        $translatePartialLoader.addPart('hearingType');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('person'); // This is required when adding child from home screen
                        return $translate.refresh();
                    }]
                }
            })
            .state('fli-person.new', {
                parent: 'fli-person',
                url: '',
                data: {
                    authorities: ['ROLE_CENTRE_SUPERVISOR', 'ROLE_CENTRE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    console.log("onEnter $state", $state);
                    console.log("onEnter $uibModal", $uibModal);

                    $uibModal.open({
                        templateUrl: 'app/fli/person-detail/person-dialog.html',
                        controller: 'FliPersonDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                var profileCategories = [];
                                var profileHolderDTO = {};
                                profileHolderDTO['profileCategories'] = profileCategories;
                                return {
                                    centreId: $stateParams.centreId,
                                    centreKey: null,
                                    dob: null,
                                    fliKey: null,
                                    hearing: null,
                                    active: null,
                                    profileHolderDTO: profileHolderDTO,
                                    id: null,
                                    userId: null,
                                    forParent: false
                                };
                            },
                            parents: null
                        }
                    }).result.then(function (result) {
                        console.log("onEnter result:", result);
                        // Navigate to the new person
                        $state.go('fli-person-detail', {centreId: result.centreId, personId: result.id});
                    }, function () {
                        console.log("Cancel/Close");
                        $state.go('fli-person');
                    });
                }]
            })
            .state('fli-person.newparent', {
                parent: 'fli-person',
                url: '',
                data: {
                    authorities: ['ROLE_CENTRE_SUPERVISOR', 'ROLE_CENTRE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    console.log("onEnter $state", $state);
                    console.log("onEnter $uibModal", $uibModal);

                    $uibModal.open({
                        templateUrl: 'app/fli/person-detail/person-dialog.html',
                        controller: 'FliPersonDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                var profileCategories = [];
                                var profileHolderDTO = {};
                                profileHolderDTO['profileCategories'] = profileCategories;
                                return {
                                    centreId: $stateParams.centreId,
                                    centreKey: null,
                                    dob: null,
                                    fliKey: null,
                                    // hearing: 'ALL',
                                    hearing: 'TYPICAL',
                                    active: null,
                                    profileHolderDTO: profileHolderDTO,
                                    id: null,
                                    userId: null,
                                    forParent: true
                                };
                            },
                            parents: ['$stateParams', 'CentreUsers', function($stateParams, CentreUsers) {
                                console.log("$stateParams", $stateParams);
                                return CentreUsers.query({centreId: $stateParams.centreId});
                            }]
                        }
                    }).result.then(function (result) {
                        console.log("onEnter result:", result);
                        // Navigate to the new person
                        $state.go('fli-person-detail', {centreId: result.centreId, personId: result.id});
                    }, function () {
                        console.log("Cancel/Close");
                        $state.go('fli-person');
                    });
                }]
            })
            .state('fli-person.delete', {
                parent: 'fli-person',
                url: '/{personId}/delete',
                data: {
                    authorities: ['ROLE_CENTRE_SUPERVISOR', 'ROLE_CENTRE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    console.log("$stateParams", $stateParams);

                    $uibModal.open({
                        templateUrl: 'app/fli/person/person-delete-dialog.html',
                        controller: 'FliPersonDeleteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['FliPerson', function (FliPerson) {
                                console.log("$stateParams", $stateParams);
                                return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        console.log("FliPersonDeleteDialogController then");
                        $state.go('fli-person', null, { reload: true });
                    }, function() {
                        console.log("FliPersonDeleteDialogController go");
                        $state.go('^');
                    });
                }]
            })

            .state('fli-person-home', {
                parent: 'entity',
                url: '/c/:centreId/p/:personId?page&sort&search',
                data: {
                    authorities: ['ROLE_CENTRE_SUPERVISOR', 'ROLE_CENTRE'],
                    pageTitle: 'fliApp.person.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/fli/person/person-home.html',
                        controller: 'FliPersonHomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fliPerson');
                        $translatePartialLoader.addPart('fli');
                        $translatePartialLoader.addPart('hearingType');
                        $translatePartialLoader.addPart('person');  // This is required when adding child from people screen
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FliPerson', function ($stateParams, FliPerson) {
                        console.log("entity $stateParams", $stateParams);
                        return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        console.log("previousState $state", $state);
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
        ;
    }
})();

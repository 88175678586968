(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.showCentre = showCentre;
        vm.showUser = showUser;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.user = Principal.user;
        vm.centre = Principal.centre;
        vm.centres = Principal.centres;
        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.canChangeCentre = canChangeCentre;
        vm.$state = $state;

        function canChangeCentre() {
            // console.log("canChangeCentre", Principal.centres());
            return Principal && Principal.centres() && Principal.centres().length > 1;
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function showCentre() {
            return vm.isAuthenticated() && vm.centre() != null;
        }

        function showUser() {
            return vm.isAuthenticated();
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('SuperManagementDeleteController', SuperManagementDeleteController);

    SuperManagementDeleteController.$inject = ['$uibModalInstance', 'entity', 'SuperUser'];

    function SuperManagementDeleteController ($uibModalInstance, entity, SuperUser) {
        var vm = this;

        vm.user = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (login) {
            SuperUser.delete({login: login},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('SuperManagementDialogController',SuperManagementDialogController);

    SuperManagementDialogController.$inject = ['$uibModalInstance', 'entity', 'SuperUser', 'JhiLanguageService', 'centres'];

    function SuperManagementDialogController ($uibModalInstance, entity, SuperUser, JhiLanguageService, centres) {
        var vm = this;

        vm.authorities = ['ROLE_ADMIN', 'ROLE_SUPERVISOR'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.centres = centres;

        console.log("centres:", vm.centres);
        console.log("user:", vm.user);

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            console.log("user:", vm.user);
            // override the login with the email
            vm.user.login = vm.user.email;
            vm.isSaving = true;
            if (vm.user.id !== null) {
                SuperUser.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                SuperUser.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();

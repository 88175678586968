(function () {
    'use strict';

    angular
        .module('fliApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('fli-session', {
                parent: 'app',
                url: '/c/:centreId/p/:personId/session',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/fli/session/session.html',
                        controller: 'FliSessionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fli');
                        $translatePartialLoader.addPart('fliPerson');
                        $translatePartialLoader.addPart('fliSession');
                        $translatePartialLoader.addPart('hearingType');
                        $translatePartialLoader.addPart('eventCategoryType');
                        $translatePartialLoader.addPart('eventType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FliPerson', function ($stateParams, FliPerson) {
                        console.log("entity $stateParams", $stateParams);
                        return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('fli-session.edit', {
                parent: 'fli-session',
                url: '/edit/:sessionId',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CENTRE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/fli/session/session-dialog.html',
                        controller: 'FliSessionDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            person: ['$stateParams', 'FliPerson', function ($stateParams, FliPerson) {
                                console.log("resolve person $stateParams", $stateParams);
                                return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                            }],
                            session: ['PersonSessionCurrent', function (PersonSessionCurrent) {
                                console.log("resolve session $stateParams", $stateParams);
                                return PersonSessionCurrent.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('fli-session.notes', {  // Same as history.notes but with different parent
                parent: 'fli-session',
                url: '/edit/notes/:sessionId',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CENTRE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/fli/session/session-note-dialog.html',
                        controller: 'FliSessionNoteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PersonSession', function (PersonSession) {
                                return PersonSession.get({centreId: $stateParams.centreId, personId: $stateParams.personId, sessionId: $stateParams.sessionId}).$promise;
                            }]
                        }
                    }).result.then(function (data) {
                        console.log("CLOSE (save) CALLED:", data);
                        $state.go('^', {}, {reload: false});
                    }, function (data) {
                        console.log("DISMISS CALLED:", data);
                        $state.go('^');
                    });
                }]
            })

            .state('history', {
                parent: 'fli-session',
                url: '/history',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/fli/session/session-history.html',
                        controller: 'FliSessionHistoryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fli');
                        $translatePartialLoader.addPart('fliPerson');
                        $translatePartialLoader.addPart('fliSession');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FliPerson', function ($stateParams, FliPerson) {
                        console.log("entity $stateParams", $stateParams);
                        return FliPerson.get({centreId: $stateParams.centreId, personId: $stateParams.personId}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'person',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('history.notes', {
                parent: 'history',
                url: '/edit/notes/:sessionId',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CENTRE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/fli/session/session-note-dialog.html',
                        controller: 'FliSessionNoteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['PersonSession', function (PersonSession) {
                                return PersonSession.get({centreId: $stateParams.centreId, personId: $stateParams.personId, sessionId: $stateParams.sessionId}).$promise;
                            }]
                        }
                    }).result.then(function (data) {
                        console.log("CLOSE (save) CALLED:", data);
                        $state.go('^', {}, {reload: false});
                    }, function (data) {
                        console.log("DISMISS CALLED:", data);
                        $state.go('^');
                    });
                }]
            })


        ;
    }
})();

(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('UserApplicationDetailController', UserApplicationDetailController);

    UserApplicationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'UserApplication', 'User', 'Application'];

    function UserApplicationDetailController($scope, $rootScope, $stateParams, previousState, entity, UserApplication, User, Application) {
        var vm = this;

        vm.userApplication = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('fliApp:userApplicationUpdate', function(event, result) {
            vm.userApplication = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

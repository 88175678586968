(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliPersonDialogController', FliPersonDialogController);

    FliPersonDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FliPerson', 'DateUtils', 'PersonDetailUtils', 'parents', 'Principal'];

    function FliPersonDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, FliPerson, DateUtils, PersonDetailUtils, parents, Principal) {
        var vm = this;

        vm.category = PersonDetailUtils.categories();
        vm.countries = PersonDetailUtils.countries();
        vm.languages = PersonDetailUtils.languages();

        console.log('$stateParams', $stateParams);
        console.log('entity', entity);

        vm.person = entity;
        vm.parents = parents;
        vm.currentAccount = null;
        vm.clear = clear;
        vm.showParentList = showParentList;
        vm.showHearingType = showHearingType;
        vm.isForParent = isForParent;
        vm.datePickerOpenStatus = {};
        vm.datePickerOptions = {
            showWeeks: false,
            minDate: new Date(1900, 0, 1),
            maxDate: new Date()
        };
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isNew = $stateParams.profileCategoryId == null || $stateParams.profileCategoryId == "";
        console.log('vm.isNew', vm.isNew);


        vm.isNewChild = vm.person.id == null;
        console.log('vm.isNewChild', vm.isNewChild);
        console.log('vm', vm);

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            console.log('vm.currentAccount', vm.currentAccount);
        });

        function isForParent() {
            if (vm.currentAccount.authorities.includes("ROLE_ADMIN")) {
                // ??
            }
            return vm.person.forParent == true;
        }

        function showParentList() {
            return vm.person.forParent == true;
        }

        function showHearingType() {
            var rtn = false;
            // console.log('vm.currentAccount.preferences', vm.currentAccount.preferences);
            for (var i in vm.currentAccount.preferences) {
                var preference = vm.currentAccount.preferences[i];
                // console.log('preference', preference);
                if (preference["key"] == 'PREFERRED_SUPPORTED_HEARING_TYPE') {
                    // console.log('preference[value]', preference['value']);
                    rtn = preference['value'] == 'ALL';
                }
            }
            return rtn;
        }

        if (vm.isNew) {
            // vm.profileCategory = PersonDetailUtils.profileCategory($stateParams.category);
            vm.profileCategory = PersonDetailUtils.profileCategory($stateParams.category, vm.person.profileHolderDTO.profileCategories);
        } else {
            vm.profileCategory = PersonDetailUtils.profileCategory($stateParams.category, vm.person.profileHolderDTO.profileCategories, $stateParams.profileCategoryId);
        }
        console.log('vm.profileCategory', vm.profileCategory);

        vm.date = null;
        vm.dateformat = DateUtils.dateformat();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            console.log('save', vm.person);
            console.log('vm.personAdditionalSpokenLanguages: ', vm.personAdditionalSpokenLanguages);
            console.log('vm.personAdditionalLanguages: ', vm.personAdditionalLanguages);

            if (vm.person.profileHolderDTO) {
                PersonDetailUtils.updateLists(vm.person.profileHolderDTO.profileCategories, vm.personAdditionalSpokenLanguages, 'IDENTIFIER_ADDITIONAL_SPOKEN_LANGUAGE');
                PersonDetailUtils.updateLists(vm.person.profileHolderDTO.profileCategories, vm.personAdditionalLanguages, 'IDENTIFIER_ADDITIONAL_LANGUAGE');
                PersonDetailUtils.convertDates(vm.person.profileHolderDTO.profileCategories, vm.profileCategory);
            }
            console.log('save', vm.person);

            if (vm.person.id == null) {
                vm.person.profileHolderDTO = null;
            }

            console.log('$stateParams', $stateParams);

            vm.isSaving = true;
            if (vm.person.id !== null) {
                FliPerson.update(vm.person, onUpdateSuccess, onSaveError);
            } else {
                FliPerson.save(vm.person, onCreateSuccess, onSaveError);
            }
        }

        function onCreateSuccess(result) {
            console.log('onCreateSuccess emit fliApp:personUpdate', result);
            $scope.$emit('fliApp:personUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }


        function onUpdateSuccess(result) {
            console.log('onUpdateSuccess emit fliApp:personUpdate', result);
            $scope.$emit('fliApp:personUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.activated = false;
        vm.datePickerOpenStatus.surgery = false;

        function openCalendar(key) {
            vm.datePickerOpenStatus[key] = true;
        }

        vm.personAdditionalSpokenLanguages = [];
        vm.personAdditionalLanguages = [];
        if (vm.profileCategory) {
            for (var i in vm.languages) {
                if (vm.profileCategory.additionalSpokenLanguages) {
                    for (var j in vm.profileCategory.additionalSpokenLanguages) {
                        if (vm.languages[i]["key"] == vm.profileCategory.additionalSpokenLanguages[j].value) {
                            vm.personAdditionalSpokenLanguages.push(vm.languages[i]);
                        }
                    }
                }
                if (vm.profileCategory.additionalLanguages) {
                    for (var j in vm.profileCategory.additionalLanguages) {
                        if (vm.languages[i]["key"] == vm.profileCategory.additionalLanguages[j].value) {
                            vm.personAdditionalLanguages.push(vm.languages[i]);
                        }
                    }
                }
            }
        }
        console.log('vm.personAdditionalSpokenLanguages: ', vm.personAdditionalSpokenLanguages);
        console.log('vm.personAdditionalLanguages: ', vm.personAdditionalLanguages);

    }
})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliPersonController', FliPersonController);

    FliPersonController.$inject = ['$scope', '$state', '$stateParams', 'DateUtils', 'FliPerson', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal'];

    function FliPersonController($scope, $state, $stateParams, DateUtils, FliPerson, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        var vm = this;

        vm.currentAccount = null;
        vm.preferredSessionType = null;
        vm.preferredLastApplication = null;
        vm.preferredSupportedHearingType = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.centreId = $stateParams.centreId;     // save this here, so we can pass to transitionTo
        vm.dateformat = DateUtils.dateformat();
        vm.showParent = false;

        Principal.identity().then(function (account) {
            vm.currentAccount = account;
            vm.preferredSessionType = Principal.preferredSessionType();
            vm.preferredSupportedHearingType = Principal.preferredSupportedHearingType();
            vm.preferredLastApplication = Principal.preferredLastApplication();
            vm.showParent = vm.currentAccount.authorities.indexOf('ROLE_CENTRE') > -1 || vm.currentAccount.authorities.indexOf('ROLE_CENTRE_SUPERVISOR') > -1;
        });

        loadAll();

        function loadAll() {
            console.log("loadAll");
            console.log("loadAll $stateParams", $stateParams);
            console.log("loadAll $state", $state);

            FliPerson.query({
                centreId: $stateParams.centreId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.people = data;
                vm.page = pagingParams.page;
                console.log("loadAll", vm.people);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                centreId: vm.centreId,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();

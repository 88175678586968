(function () {
    'use strict';
    angular
        .module('fliApp')
        .factory('FliPerson', FliPerson)
        .factory('FliPersonProfile', FliPersonProfile);

    FliPerson.$inject = ['$resource', 'DateUtils'];
    FliPersonProfile.$inject = ['$resource'];

    function FliPerson($resource, DateUtils) {
        var resourceUrl = 'api/centre/:centreId/person/:personId';

        return $resource(resourceUrl, {centreId: '@centreId', personId: '@personId'}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    return angular.toJson(copy);
                }
            },
            'delete': {
                method: 'DELETE'
            }
        });
    }

    function FliPersonProfile($resource) {
        var resourceUrl = 'api/centre/:centreId/person/:personId/profile/:profileCategoryId';

        return $resource(resourceUrl, {centreId: '@centreId', personId: '@personId', profileCategoryId: '@profileCategoryId'}, {
            'delete': {method: 'DELETE'}
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('fliApp')
        .factory('SessionUtils', SessionUtils)
        .factory('PersonSessionCurrent', PersonSessionCurrent)
        .factory('PersonSession', PersonSession)
        .factory('PersonSessions', PersonSessions);

    PersonSessionCurrent.$inject = ['$resource', 'DateUtils'];
    PersonSession.$inject = ['$resource', 'DateUtils'];
    PersonSessions.$inject = ['$resource', 'DateUtils'];

    function SessionUtils () {

        var service = {
            sortSessionData : sortSessionData
        };
        return service;

        function sortSessionData(session) {

            var observations = [];

            var tmpObservations = {};
            var tmpKeys = [];

            for (var i = 0; i < session.observations.length; i++) {
                var observation = session.observations[i];
                var phase = observation.eventTypeKey;
                if (!tmpObservations[phase]) {
                    tmpObservations[phase] = [];
                }
                tmpObservations[phase].push(observation);

                tmpKeys.push([phase, observation.eventOrder]);
            }
            console.log("tmpObservations", tmpObservations);

            console.log("tmpKeys", tmpKeys);
            tmpKeys.sort(function (a, b) {
                // console.log(a[1] + ":" + b[1]);
                return a[1] - b[1];
            });
            console.log("tmpKeys", tmpKeys);

            var phaseKeys = [];
            var lastKey = null;
            for (var tmpKeyIndex = 0; tmpKeyIndex < tmpKeys.length; tmpKeyIndex++) {
                var key = tmpKeys[tmpKeyIndex][0];
                if (!lastKey) {
                    phaseKeys.push(key)
                } else if (lastKey != key) {
                    phaseKeys.push(key)
                }
                lastKey = key;
            }
            console.log("phaseKeys", phaseKeys);

            for (var phaseKeyIndex = 0; phaseKeyIndex < phaseKeys.length; phaseKeyIndex++) {
                var phaseKey = phaseKeys[phaseKeyIndex];
                tmpObservations[phaseKey].sort(function (a, b) {
                    return a.eventOrder - b.eventOrder;
                });

                var phaseObs = {};
                phaseObs['phase'] = phaseKey;
                phaseObs['show'] = 'phase';
                observations.push(phaseObs);

                for (var observationIndex = 0; observationIndex < tmpObservations[phaseKey].length; observationIndex++) {
                    var obs = {};
                    var tmpObservation = tmpObservations[phaseKey][observationIndex];
                    obs['observation'] = tmpObservation;
                    if (tmpObservation.observed != null) {
                        obs['show'] = 'observed';
                    }
                    observations.push(obs);
                }
            }

            console.log("observations", observations);

            return observations;
        }

    }

    function PersonSessionCurrent ($resource, DateUtils) {
        var resourceUrl =  'api/sessions/centre/:centreId/person/:personId/current';

        return $resource(resourceUrl, {}, {
            // 'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            }
        });
    }

    function PersonSession ($resource, DateUtils) {
        var resourceUrl =  'api/sessions/centre/:centreId/person/:personId/session/:sessionId';

        return $resource(resourceUrl, {}, {
            // 'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            }
        });
    }

    function PersonSessions ($resource, DateUtils) {
        var resourceUrl =  'api/sessions/centre/:centreId/person/:personId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertLocalDateFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                }
            }
        });
    }

})();

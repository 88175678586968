(function () {
    'use strict';
    angular
        .module('fliApp')
        .factory('PersonDetailUtils', PersonDetailUtils);


    PersonDetailUtils.$inject = ['uibDateParser', 'DateUtils'];

    function PersonDetailUtils(uibDateParser, DateUtils) {

        var service = {
            profileCategory: profileCategory,
            convertDates: convertDates,
            updateLists: updateLists,
            categories: categories,
            template: template,
            identifier: identifier,
            hearingLoss: hearingLoss,
            hearingLossSide: hearingLossSide,
            intervention: intervention,
            devices: devices,
            countries: countries,
            languages: languages
        };
        return service;

        function template(key) {
            console.log('template: ' + key);
            if (key == "ident") {
                return "person-dialog.html"
            } else if (key == "device") {
                return "person-dialog-devices.html"
            } else if (key == "loss") {
                return "person-dialog-hearing-loss.html"
            } else if (key == "lossSide") {
                return "person-dialog-hearing-loss-side.html"
            } else if (key == "intervention") {
                return "person-dialog-intervention.html"
            }
        }

        function categories() {
            return {
                'PROFILE_CAT_IDENTIFIER': 'ident',
                'ident': 'PROFILE_CAT_IDENTIFIER',
                'PROFILE_CAT_DEVICES': 'device',
                'device': 'PROFILE_CAT_DEVICES',
                'PROFILE_CAT_HEARING_LOSS': 'loss',
                'loss': 'PROFILE_CAT_HEARING_LOSS',
                'PROFILE_CAT_HEARING_LOSS_SIDE': 'lossSide',
                'lossSide': 'PROFILE_CAT_HEARING_LOSS_SIDE',
                'PROFILE_CAT_EARLY_INTERVENTION': 'intervention',
                'intervention': 'PROFILE_CAT_EARLY_INTERVENTION'
            };
        }

        function profileCategory(category, data, id) {
            console.log('profileCategory: ' + category + ' id ', id);
            var result;
            if (categories()[category] == 'PROFILE_CAT_IDENTIFIER') {
                if (id == null) {
                    result = newIdentifier(data);
                } else {
                    result = identifier(data, id);
                }
            } else if (categories()[category] == 'PROFILE_CAT_HEARING_LOSS') {
                if (id == null) {
                    result = newHearingLoss(data);
                } else {
                    result = hearingLoss(data, id);
                }
            } else if (categories()[category] == 'PROFILE_CAT_HEARING_LOSS_SIDE') {
                if (id == null) {
                    result = newHearingLossSide(data);
                } else {
                    result = hearingLossSide(data, id);
                }
            } else if (categories()[category] == 'PROFILE_CAT_DEVICES') {
                if (id == null) {
                    result = newDevice(data);
                } else {
                    result = devices(data, id);
                }
            } else if (categories()[category] == 'PROFILE_CAT_EARLY_INTERVENTION') {
                if (id == null) {
                    result = newIntervention(data);
                } else {
                    result = intervention(data, id);
                }
            }
            if (result instanceof Array && result.length == 1) {
                return result[0];
            } else {
                return result;
            }
        }

        function updateLists(data, newData, key) {
            console.log('xxx updateLists: ', data, ' newData ', newData, ' key ', key);
            for (var i in data) {
                for (var index in data[i]) {
                    var profileCategory = data[i][index];
                    var profiles = profileCategory['profiles'];

                    if (i == 'PROFILE_CAT_IDENTIFIER') {
                        for (var profile in profiles) {
                            if (profiles[profile]['key'] == key) {
                                var keep = 0;
                                for (var j in newData) {
                                    var language = newData[j]["key"];
                                    if (language == profiles[profile]["value"]) {
                                        keep = 1;
                                    }
                                }
                                if (keep == 0) {
                                    profiles[profile]["value"] = "";
                                }
                            }
                        }

                        for (var j in newData) {
                            var language = newData[j]["key"];
                            var toAdd = 1;
                            for (var profile in profiles) {
                                if (profiles[profile]['key'] == key) {
                                    if (language == profiles[profile]["value"]) {
                                        toAdd = 0;
                                    }
                                }
                            }
                            if (toAdd == 1) {
                                var entry = {};
                                entry['id'] = null;
                                entry['key'] = key;
                                entry['value'] = language;
                                profiles.push(entry);
                            }
                        }
                    }
                }
            }
        }

        function convertDates(data, newData) {
            console.log('xxx convertDates: ', data, ' newData ', newData);

            for (var i in data) {
                if (newData && i == newData['category']) {
                    for (var index in data[i]) {
                        var profileCategory = data[i][index];
                        if (profileCategory['id'] == newData['id']) {
                            profileCategory['date'] = toDate(newData['date']);

                            console.log("i:", i);
                            if (i == 'PROFILE_CAT_DEVICES') {
                                var profiles = profileCategory['profiles'];
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == 'DEVICES_ACTIVATED') {
                                        var newDate = toDate(newData['activated'].value);
                                        if (newDate) {
                                            profiles[profile].value = newDate;
                                        }
                                    }
                                    if (profiles[profile]['key'] == 'DEVICES_SURGERY') {
                                        var newDate = toDate(newData['surgery'].value);
                                        if (newDate) {
                                            profiles[profile].value = newDate;
                                        }
                                    }
                                }
                            } else if (i == 'PROFILE_CAT_HEARING_LOSS') {
                                var profiles = profileCategory['profiles'];
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSIS_DATE') {
                                        var newDate = toDate(newData['diagnosisDate'].value);
                                        if (newDate) {
                                            profiles[profile].value = newDate;
                                        }
                                    }
                                }
                            } else if (i == 'PROFILE_CAT_EARLY_INTERVENTION') {
                                var profiles = profileCategory['profiles'];
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == 'INTERVENTION_COMMENCED') {
                                        var newDate = toDate(newData['commenced'].value);
                                        if (newDate) {
                                            profiles[profile].value = newDate;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        function toDate(data) {
            return DateUtils.convertLocalDateToServer(data);
        }

        function fromDate(data) {
            return DateUtils.convertLocalDateFromServer(data);
        }

        // --------------------------------------------------------------------
        function uiIdentifier() {
            var entry = {};
            entry['category'] = 'PROFILE_CAT_IDENTIFIER';
            entry['id'] = null;
            entry['date'] = null;

            entry['gender'] = null;
            entry['providedBy'] = null;
            entry['interpreter'] = null;
            entry['postcode'] = null;
            entry['additionalNeeds'] = null;
            entry['primaryLanguage'] = null;
            entry['additionalSpokenLanguages'] = null;
            entry['additionalLanguages'] = null;
            entry['therapyHours'] = null;
            entry['birthCountry'] = null;
            return entry;
        }

        function newIdentifierCategory() {
            var cat = {};
            cat['id'] = null;
            cat['date'] = new Date();

            cat['profileCategory'] = 'PROFILE_CAT_IDENTIFIER';
            cat['profiles'] = [];
            var profiles = ['IDENTIFIER_GENDER', 'IDENTIFIER_DATA_PROVIDED_BY', 'IDENTIFIER_INTERPRETER', 'IDENTIFIER_POSTCODE', 'IDENTIFIER_ADDITIONAL_NEEDS', 'IDENTIFIER_PRIMARY_SPOKEN_LANGUAGE', 'IDENTIFIER_ADDITIONAL_SPOKEN_LANGUAGE', 'IDENTIFIER_ADDITIONAL_LANGUAGE', 'IDENTIFIER_HOURS_THERAPY', 'IDENTIFIER_COUNTRY_BIRTH'];
            profiles.forEach(function (s) {
                var profile = {};
                profile['id'] = null;
                profile['key'] = s;
                profile['value'] = null;
                cat['profiles'].push(profile);
            });
            return cat;
        }

        function newIdentifier(data) {
            console.log('data: ', data);
            var result = [];
            var key = 'PROFILE_CAT_IDENTIFIER';
            if (data[key] == null) {
                data[key] = [];
            }
            for (var i in data) {
                if (i == key) {
                    var profileCategory = newIdentifierCategory();
                    data[i].push(profileCategory);

                    var entry = uiIdentifier();
                    entry['id'] = profileCategory['id'];
                    entry['date'] = profileCategory['date'];

                    entry['additionalSpokenLanguages'] = [];
                    entry['additionalLanguages'] = [];

                    var profiles = profileCategory['profiles'];
                    for (var profile in profiles) {
                        if (profiles[profile]['key'] == 'IDENTIFIER_GENDER') {
                            entry['gender'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_DATA_PROVIDED_BY') {
                            entry['providedBy'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_INTERPRETER') {
                            entry['interpreter'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_POSTCODE') {
                            entry['postcode'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_ADDITIONAL_NEEDS') {
                            entry['additionalNeeds'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_PRIMARY_SPOKEN_LANGUAGE') {
                            entry['primaryLanguage'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_ADDITIONAL_SPOKEN_LANGUAGE') {
                            entry['additionalSpokenLanguages'].push(profiles[profile])
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_ADDITIONAL_LANGUAGE') {
                            entry['additionalLanguages'].push(profiles[profile])
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_HOURS_THERAPY') {
                            entry['therapyHours'] = profiles[profile];
                        } else if (profiles[profile]['key'] == 'IDENTIFIER_COUNTRY_BIRTH') {
                            entry['birthCountry'] = profiles[profile];
                        }
                    }
                    result.push(entry);
                }
            }
            console.log("result", result);
            return result;
        }

        function identifier(data, id) {
            console.log('data: ', data);
            var result = [];
            for (var i in data) {
                if (i == 'PROFILE_CAT_IDENTIFIER') {
                    for (var index in data[i]) {
                        var profileCategory = data[i][index];
                        console.log('i: ' + i + '. index: ', index);
                        if (id == null || id == profileCategory['id']) {
                            var entry = uiIdentifier();
                            entry['id'] = profileCategory['id'];
                            entry['date'] = fromDate(profileCategory['date']);

                            var profiles = profileCategory['profiles'];

                            var profileList = ['IDENTIFIER_GENDER', 'IDENTIFIER_DATA_PROVIDED_BY', 'IDENTIFIER_INTERPRETER', 'IDENTIFIER_POSTCODE', 'IDENTIFIER_ADDITIONAL_NEEDS', 'IDENTIFIER_PRIMARY_SPOKEN_LANGUAGE', 'IDENTIFIER_ADDITIONAL_SPOKEN_LANGUAGE', 'IDENTIFIER_ADDITIONAL_LANGUAGE', 'IDENTIFIER_HOURS_THERAPY', 'IDENTIFIER_COUNTRY_BIRTH'];
                            profileList.forEach(function (s) {
                                var newFlag = true;
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == s) {
                                        newFlag = false;
                                    }
                                }

                                if (newFlag) {
                                    var missingProfile = {};
                                    missingProfile['id'] = null;
                                    missingProfile['key'] = s;
                                    missingProfile['value'] = null;
                                    profileCategory['profiles'].push(missingProfile);
                                }
                            });

                            for (var profile in profiles) {
                                if (profiles[profile]['key'] == 'IDENTIFIER_GENDER') {
                                    entry['gender'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_DATA_PROVIDED_BY') {
                                    entry['providedBy'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_INTERPRETER') {
                                    entry['interpreter'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_POSTCODE') {
                                    entry['postcode'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_ADDITIONAL_NEEDS') {
                                    entry['additionalNeeds'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_PRIMARY_SPOKEN_LANGUAGE') {
                                    entry['primaryLanguage'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_ADDITIONAL_SPOKEN_LANGUAGE') {
                                    if (!entry['additionalSpokenLanguages']) {
                                        entry['additionalSpokenLanguages'] = []
                                    }
                                    entry['additionalSpokenLanguages'].push(profiles[profile])
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_ADDITIONAL_LANGUAGE') {
                                    if (!entry['additionalLanguages']) {
                                        entry['additionalLanguages'] = []
                                    }
                                    entry['additionalLanguages'].push(profiles[profile])
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_HOURS_THERAPY') {
                                    entry['therapyHours'] = profiles[profile];
                                } else if (profiles[profile]['key'] == 'IDENTIFIER_COUNTRY_BIRTH') {
                                    entry['birthCountry'] = profiles[profile];
                                }
                            }
                            result.push(entry);
                        }
                    }
                }
            }
            console.log('data: ', data);
            console.log("result", result);
            return result;
        }

        // --------------------------------------------------------------------
        function uiHearingLoss() {
            var entry = {};
            entry['category'] = 'PROFILE_CAT_HEARING_LOSS';
            entry['id'] = null;
            entry['date'] = null;

            entry['newborn'] = null;
            entry['diagnosed'] = null;
            entry['permanent'] = null;
            entry['diagnosisDate'] = null;
            entry['aetiology'] = null;
            entry['diagnosisTime'] = null;
            entry['stability'] = null;
            return entry;
        }

        function newHearingLossCategory() {
            var cat = {};
            cat['id'] = null;
            cat['date'] = new Date();

            cat['profileCategory'] = 'PROFILE_CAT_HEARING_LOSS';
            cat['profiles'] = [];
            var profiles = ['HEARING_LOSS_NEWBORN', 'HEARING_LOSS_DIAGNOSED', 'HEARING_LOSS_PERMANENT',
                'HEARING_LOSS_DIAGNOSIS_DATE', 'HEARING_LOSS_AETIOLOGY', 'HEARING_LOSS_DIAGNOSIS_TIME', 'HEARING_LOSS_STABILITY'];
            profiles.forEach(function (s) {
                var profile = {};
                profile['id'] = null;
                profile['key'] = s;
                profile['value'] = null;
                cat['profiles'].push(profile);
            });
            return cat;
        }

        function newHearingLoss(data) {
            console.log('data: ', data);
            var result = [];
            var key = 'PROFILE_CAT_HEARING_LOSS';
            if (data[key] == null) {
                data[key] = [];
            }
            for (var i in data) {
                if (i == key) {
                    var profileCategory = newHearingLossCategory();
                    data[i].push(profileCategory);

                    var entry = uiHearingLoss();
                    entry['id'] = profileCategory['id'];
                    entry['date'] = profileCategory['date'];

                    var profiles = profileCategory['profiles'];
                    for (var profile in profiles) {
                        if (profiles[profile]['key'] == 'HEARING_LOSS_NEWBORN') {
                            entry['newborn'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSED') {
                            entry['diagnosed'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_PERMANENT') {
                            entry['permanent'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSIS_DATE') {
                            entry['diagnosisDate'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_AETIOLOGY') {
                            entry['aetiology'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSIS_TIME') {
                            entry['diagnosisTime'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_STABILITY') {
                            entry['stability'] = profiles[profile];
                        }
                    }
                    result.push(entry);
                }
            }
            console.log("result", result);
            return result;
        }

        function hearingLoss(data, id) {
            console.log('data: ', data);
            var result = [];
            for (var i in data) {
                console.log('Key is: ' + i + '. Value is: ', data[i]);
                if (i == 'PROFILE_CAT_HEARING_LOSS') {
                    for (var index in data[i]) {
                        var profileCategory = data[i][index];
                        console.log('i: ' + i + '. index: ', index);
                        if (id == null || id == profileCategory['id']) {
                            var entry = uiHearingLoss();
                            entry['id'] = profileCategory['id'];
                            entry['date'] = fromDate(profileCategory['date']);

                            var profiles = profileCategory['profiles'];

                            var profileList = ['HEARING_LOSS_NEWBORN', 'HEARING_LOSS_DIAGNOSED', 'HEARING_LOSS_PERMANENT',
                                'HEARING_LOSS_DIAGNOSIS_DATE', 'HEARING_LOSS_AETIOLOGY', 'HEARING_LOSS_DIAGNOSIS_TIME', 'HEARING_LOSS_STABILITY'];

                            profileList.forEach(function (s) {
                                var newFlag = true;
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == s) {
                                        newFlag = false;
                                    }
                                }

                                if (newFlag) {
                                    var missingProfile = {};
                                    missingProfile['id'] = null;
                                    missingProfile['key'] = s;
                                    missingProfile['value'] = null;
                                    profileCategory['profiles'].push(missingProfile);
                                }
                            });


                            for (var profile in profiles) {
                                if (profiles[profile]['key'] == 'HEARING_LOSS_NEWBORN') {
                                    entry['newborn'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSED') {
                                    entry['diagnosed'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_PERMANENT') {
                                    entry['permanent'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSIS_DATE') {
                                    entry['diagnosisDate'] = profiles[profile];
                                    var diagnosisDate = uibDateParser.parse(profiles[profile].value, 'yyyy-MM-dd');
                                    if (diagnosisDate) {
                                        entry['diagnosisDate'].value = diagnosisDate;
                                    } else {
                                        entry['diagnosisDate'].value = null;
                                    }
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_AETIOLOGY') {
                                    entry['aetiology'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_DIAGNOSIS_TIME') {
                                    entry['diagnosisTime'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_STABILITY') {
                                    entry['stability'] = profiles[profile];
                                }
                            }
                            result.push(entry);
                        }
                    }
                }
            }
            console.log("result", result);
            return result;
        }

        // --------------------------------------------------------------------
        function uiHearingLossSide() {
            var entry = {};
            entry['category'] = 'PROFILE_CAT_HEARING_LOSS_SIDE';
            entry['id'] = null;
            entry['date'] = null;

            entry['side'] = null;
            entry['level'] = null;
            entry['type'] = null;
            return entry;
        }

        function newHearingLossSideCategory() {
            var cat = {};
            cat['id'] = null;
            cat['date'] = new Date();

            cat['profileCategory'] = 'PROFILE_CAT_HEARING_LOSS_SIDE';
            cat['profiles'] = [];
            var profiles = ['HEARING_LOSS_SIDE_EAR', 'HEARING_LOSS_SIDE_LEVEL', 'HEARING_LOSS_SIDE_TYPE'];
            profiles.forEach(function (s) {
                var profile = {};
                profile['id'] = null;
                profile['key'] = s;
                profile['value'] = null;
                cat['profiles'].push(profile);
            });
            return cat;
        }

        function newHearingLossSide(data) {
            console.log('data: ', data);
            var result = [];
            var key = 'PROFILE_CAT_HEARING_LOSS_SIDE';
            if (data[key] == null) {
                data[key] = [];
            }
            for (var i in data) {
                if (i == key) {
                    var profileCategory = newHearingLossSideCategory();
                    data[i].push(profileCategory);

                    var entry = uiHearingLossSide();
                    entry['id'] = profileCategory['id'];
                    entry['date'] = profileCategory['date'];

                    var profiles = profileCategory['profiles'];
                    for (var profile in profiles) {
                        if (profiles[profile]['key'] == 'HEARING_LOSS_SIDE_EAR') {
                            entry['side'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_SIDE_LEVEL') {
                            entry['level'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'HEARING_LOSS_SIDE_TYPE') {
                            entry['type'] = profiles[profile];
                        }
                    }
                    result.push(entry);
                }
            }
            console.log("result", result);
            if (result) {
                result.sort(function (a, b) {
                    return (a.side.value > b.side.value) ? 1 : ((b.side.value > a.side.value) ? -1 : ( (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0 ) ) );
                });
            }
            console.log("result", result);

            return result;
        }

        function hearingLossSide(data, id) {
            console.log('data: ', data);
            var result = [];
            for (var i in data) {
                console.log('Key is: ' + i + '. Value is: ', data[i]);
                if (i == 'PROFILE_CAT_HEARING_LOSS_SIDE') {
                    for (var index in data[i]) {
                        var profileCategory = data[i][index];
                        console.log('i: ' + i + '. index: ', index);
                        if (id == null || id == profileCategory['id']) {
                            var entry = uiHearingLossSide();
                            entry['id'] = profileCategory['id'];
                            entry['date'] = fromDate(profileCategory['date']);

                            var profiles = profileCategory['profiles'];

                            var profileList = ['HEARING_LOSS_SIDE_EAR', 'HEARING_LOSS_SIDE_LEVEL', 'HEARING_LOSS_SIDE_TYPE'];

                            profileList.forEach(function (s) {
                                var newFlag = true;
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == s) {
                                        newFlag = false;
                                    }
                                }

                                if (newFlag) {
                                    var missingProfile = {};
                                    missingProfile['id'] = null;
                                    missingProfile['key'] = s;
                                    missingProfile['value'] = null;
                                    profileCategory['profiles'].push(missingProfile);
                                }
                            });


                            for (var profile in profiles) {
                                if (profiles[profile]['key'] == 'HEARING_LOSS_SIDE_EAR') {
                                    entry['side'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_SIDE_LEVEL') {
                                    entry['level'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'HEARING_LOSS_SIDE_TYPE') {
                                    entry['type'] = profiles[profile];
                                }
                            }
                            result.push(entry);
                        }
                    }
                }
            }
            console.log("result", result);
            if (result) {
                result.sort(function (a, b) {
                    return (a.side.value > b.side.value) ? 1 : ((b.side.value > a.side.value) ? -1 : ( (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0 ) ) );
                });
            }
            console.log("result", result);

            return result;
        }

        // --------------------------------------------------------------------
        function uiDevice() {
            var entry = {};
            entry['category'] = 'PROFILE_CAT_DEVICES';
            entry['id'] = null;
            entry['date'] = null;

            entry['activated'] = null;
            entry['surgery'] = null;
            entry['side'] = null;
            entry['device'] = null;
            entry['brand'] = null;
            return entry;
        }

        function newDeviceCategory() {
            var cat = {};
            cat['id'] = null;
            cat['date'] = new Date();

            cat['profileCategory'] = 'PROFILE_CAT_DEVICES';
            cat['profiles'] = [];
            var profiles = ['DEVICES_ACTIVATED', 'DEVICES_SURGERY', 'DEVICES_SIDE', 'DEVICES_TYPE', 'DEVICES_BRAND'];
            profiles.forEach(function (s) {
                var profile = {};
                profile['id'] = null;
                profile['key'] = s;
                profile['value'] = null;
                cat['profiles'].push(profile);
            });
            return cat;
        }

        function newDevice(data) {
            console.log('data: ', data);
            var result = [];
            var key = 'PROFILE_CAT_DEVICES';
            if (data[key] == null) {
                data[key] = [];
            }
            for (var i in data) {
                if (i == key) {
                    var profileCategory = newDeviceCategory();
                    data[i].push(profileCategory);

                    var entry = uiDevice();
                    entry['id'] = profileCategory['id'];
                    entry['date'] = profileCategory['date'];

                    var profiles = profileCategory['profiles'];
                    for (var profile in profiles) {
                        if (profiles[profile]['key'] == 'DEVICES_ACTIVATED') {
                            console.log('DEVICES_ACTIVATED: ', profiles[profile]);
                            entry['activated'] = profiles[profile];
                            // entry['activated'] = null;
                        }
                        if (profiles[profile]['key'] == 'DEVICES_SURGERY') {
                            entry['surgery'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'DEVICES_SIDE') {
                            entry['side'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'DEVICES_TYPE') {
                            entry['device'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'DEVICES_BRAND') {
                            entry['brand'] = profiles[profile];
                        }
                    }
                    result.push(entry);
                }
            }
            console.log("result", result);
            return result;
        }

        function devices(data, id) {
            console.log('data: ', data);
            var result = [];
            for (var i in data) {
                if (i == 'PROFILE_CAT_DEVICES') {
                    for (var index in data[i]) {
                        var profileCategory = data[i][index];
                        console.log('i: ' + i + '. index: ', index);
                        if (id == null || id == profileCategory['id']) {
                            var entry = uiDevice();
                            entry['id'] = profileCategory['id'];
                            entry['date'] = fromDate(profileCategory['date']);

                            var profiles = profileCategory['profiles'];

                            var profileList = ['DEVICES_ACTIVATED', 'DEVICES_SURGERY', 'DEVICES_SIDE', 'DEVICES_TYPE', 'DEVICES_BRAND'];
                            profileList.forEach(function (s) {
                                var newFlag = true;
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == s) {
                                        newFlag = false;
                                    }
                                }

                                if (newFlag) {
                                    var missingProfile = {};
                                    missingProfile['id'] = null;
                                    missingProfile['key'] = s;
                                    missingProfile['value'] = null;
                                    profileCategory['profiles'].push(missingProfile);
                                }
                            });


                            for (var profile in profiles) {
                                if (profiles[profile]['key'] == 'DEVICES_ACTIVATED') {
                                    entry['activated'] = profiles[profile];
                                    var activated = uibDateParser.parse(profiles[profile].value, 'yyyy-MM-dd');
                                    if (activated) {
                                        entry['activated'].value = activated;
                                    } else {
                                        entry['activated'].value = null;
                                    }

                                }
                                if (profiles[profile]['key'] == 'DEVICES_SURGERY') {
                                    entry['surgery'] = profiles[profile];
                                    var surgery = uibDateParser.parse(profiles[profile].value, 'yyyy-MM-dd');
                                    if (surgery) {
                                        entry['surgery'].value = surgery;
                                    } else {
                                        entry['surgery'].value = null;
                                    }
                                }
                                if (profiles[profile]['key'] == 'DEVICES_SIDE') {
                                    entry['side'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'DEVICES_TYPE') {
                                    entry['device'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'DEVICES_BRAND') {
                                    entry['brand'] = profiles[profile];
                                }
                            }
                            result.push(entry);
                        }
                    }
                }
            }
            console.log('data: ', data);
            console.log("result", result);
            if (result) {
                result.sort(function (a, b) {
                    return (a.surgery > b.surgery) ? 1 : ((b.surgery > a.surgery) ? -1 : ( (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0 ) ) );
                });
            }
            console.log("result", result);

            return result;
        }

        // --------------------------------------------------------------------
        function uiIntervention() {
            var entry = {};
            entry['category'] = 'PROFILE_CAT_EARLY_INTERVENTION';
            entry['id'] = null;
            entry['date'] = null;

            entry['commenced'] = null;
            entry['mode'] = null;
            entry['early'] = null;
            return entry;
        }

        function newInterventionCategory() {
            var cat = {};
            cat['id'] = null;
            cat['date'] = new Date();

            cat['profileCategory'] = 'PROFILE_CAT_EARLY_INTERVENTION';
            cat['profiles'] = [];
            var profiles = ['INTERVENTION_COMMENCED', 'INTERVENTION_MODE', 'INTERVENTION_EARLY'];
            profiles.forEach(function (s) {
                var profile = {};
                profile['id'] = null;
                profile['key'] = s;
                profile['value'] = null;
                cat['profiles'].push(profile);
            });
            return cat;
        }

        function newIntervention(data) {
            console.log('data: ', data);
            var result = [];
            var key = 'PROFILE_CAT_EARLY_INTERVENTION';
            if (data[key] == null) {
                data[key] = [];
            }
            for (var i in data) {
                if (i == key) {
                    var profileCategory = newInterventionCategory();
                    data[i].push(profileCategory);

                    var entry = uiIntervention();
                    entry['id'] = profileCategory['id'];
                    entry['date'] = profileCategory['date'];

                    var profiles = profileCategory['profiles'];
                    for (var profile in profiles) {
                        if (profiles[profile]['key'] == 'INTERVENTION_COMMENCED') {
                            entry['commenced'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'INTERVENTION_MODE') {
                            entry['mode'] = profiles[profile];
                        }
                        if (profiles[profile]['key'] == 'INTERVENTION_EARLY') {
                            entry['early'] = profiles[profile];
                        }
                    }
                    result.push(entry);
                }
            }
            console.log("result", result);
            return result;
        }

        function intervention(data, id) {
            console.log('data: ', data);
            var result = [];
            for (var i in data) {
                if (i == 'PROFILE_CAT_EARLY_INTERVENTION') {
                    for (var index in data[i]) {
                        var profileCategory = data[i][index];
                        console.log('i: ' + i + '. index: ', index);
                        if (id == null || id == profileCategory['id']) {
                            var entry = uiIntervention();
                            entry['id'] = profileCategory['id'];
                            entry['date'] = fromDate(profileCategory['date']);

                            var profiles = profileCategory['profiles'];

                            var profileList = ['INTERVENTION_COMMENCED', 'INTERVENTION_MODE', 'INTERVENTION_EARLY'];
                            profileList.forEach(function (s) {
                                var newFlag = true;
                                for (var profile in profiles) {
                                    if (profiles[profile]['key'] == s) {
                                        newFlag = false;
                                    }
                                }

                                if (newFlag) {
                                    var missingProfile = {};
                                    missingProfile['id'] = null;
                                    missingProfile['key'] = s;
                                    missingProfile['value'] = null;
                                    profileCategory['profiles'].push(missingProfile);
                                }
                            });


                            for (var profile in profiles) {
                                if (profiles[profile]['key'] == 'INTERVENTION_COMMENCED') {
                                    entry['commenced'] = profiles[profile];
                                    var commenced = uibDateParser.parse(profiles[profile].value, 'yyyy-MM-dd');
                                    if (commenced) {
                                        entry['commenced'].value = commenced;
                                    } else {
                                        entry['commenced'].value = null;
                                    }
                                }
                                if (profiles[profile]['key'] == 'INTERVENTION_MODE') {
                                    entry['mode'] = profiles[profile];
                                }
                                if (profiles[profile]['key'] == 'INTERVENTION_EARLY') {
                                    entry['early'] = profiles[profile];
                                }
                            }
                            result.push(entry);
                        }
                    }
                }
            }
            console.log('data: ', data);
            console.log("result", result);
            return result;
        }

        function countries() {
            var countries = [
                {key: '', value: ''},
                {key: 'AF', value: 'Afghanistan'},
                {key: 'AX', value: 'Aland Islands'},
                {key: 'AL', value: 'Albania'},
                {key: 'DZ', value: 'Algeria'},
                {key: 'AS', value: 'American Samoa'},
                {key: 'AD', value: 'Andorra'},
                {key: 'AO', value: 'Angola'},
                {key: 'AI', value: 'Anguilla'},
                {key: 'AQ', value: 'Antarctica'},
                {key: 'AG', value: 'Antigua and Barbuda'},
                {key: 'AR', value: 'Argentina'},
                {key: 'AM', value: 'Armenia'},
                {key: 'AW', value: 'Aruba'},
                {key: 'AU', value: 'Australia'},
                {key: 'AT', value: 'Austria'},
                {key: 'AZ', value: 'Azerbaijan'},
                {key: 'BS', value: 'Bahamas'},
                {key: 'BH', value: 'Bahrain'},
                {key: 'BD', value: 'Bangladesh'},
                {key: 'BB', value: 'Barbados'},
                {key: 'BY', value: 'Belarus'},
                {key: 'BE', value: 'Belgium'},
                {key: 'BZ', value: 'Belize'},
                {key: 'BJ', value: 'Benin'},
                {key: 'BM', value: 'Bermuda'},
                {key: 'BT', value: 'Bhutan'},
                {key: 'BO', value: 'Bolivia, Plurinational State of'},
                {key: 'BQ', value: 'Bonaire, Sint Eustatius and Saba'},
                {key: 'BA', value: 'Bosnia and Herzegovina'},
                {key: 'BW', value: 'Botswana'},
                {key: 'BV', value: 'Bouvet Island'},
                {key: 'BR', value: 'Brazil'},
                {key: 'IO', value: 'British Indian Ocean Territory'},
                {key: 'BN', value: 'Brunei Darussalam'},
                {key: 'BG', value: 'Bulgaria'},
                {key: 'BF', value: 'Burkina Faso'},
                {key: 'BI', value: 'Burundi'},
                {key: 'KH', value: 'Cambodia'},
                {key: 'CM', value: 'Cameroon'},
                {key: 'CA', value: 'Canada'},
                {key: 'CV', value: 'Cape Verde'},
                {key: 'KY', value: 'Cayman Islands'},
                {key: 'CF', value: 'Central African Republic'},
                {key: 'TD', value: 'Chad'},
                {key: 'CL', value: 'Chile'},
                {key: 'CN', value: 'China'},
                {key: 'CX', value: 'Christmas Island'},
                {key: 'CC', value: 'Cocos (Keeling) Islands'},
                {key: 'CO', value: 'Colombia'},
                {key: 'KM', value: 'Comoros'},
                {key: 'CG', value: 'Congo'},
                {key: 'CD', value: 'Congo, the Democratic Republic of the'},
                {key: 'CK', value: 'Cook Islands'},
                {key: 'CR', value: 'Costa Rica'},
                {key: 'CI', value: 'Cote dIvoire'},
                {key: 'HR', value: 'Croatia'},
                {key: 'CU', value: 'Cuba'},
                {key: 'CY', value: 'Cyprus'},
                {key: 'CZ', value: 'Czech Republic'},
                {key: 'DK', value: 'Denmark'},
                {key: 'DJ', value: 'Djibouti'},
                {key: 'DM', value: 'Dominica'},
                {key: 'DO', value: 'Dominican Republic'},
                {key: 'EC', value: 'Ecuador'},
                {key: 'EG', value: 'Egypt'},
                {key: 'SV', value: 'El Salvador'},
                {key: 'GQ', value: 'Equatorial Guinea'},
                {key: 'ER', value: 'Eritrea'},
                {key: 'EE', value: 'Estonia'},
                {key: 'ET', value: 'Ethiopia'},
                {key: 'FK', value: 'Falkland Islands (Malvinas)'},
                {key: 'FO', value: 'Faroe Islands'},
                {key: 'FJ', value: 'Fiji'},
                {key: 'FI', value: 'Finland'},
                {key: 'FR', value: 'France'},
                {key: 'GF', value: 'French Guiana'},
                {key: 'PF', value: 'French Polynesia'},
                {key: 'TF', value: 'French Southern Territories'},
                {key: 'GA', value: 'Gabon'},
                {key: 'GM', value: 'Gambia'},
                {key: 'GE', value: 'Georgia'},
                {key: 'DE', value: 'Germany'},
                {key: 'GH', value: 'Ghana'},
                {key: 'GI', value: 'Gibraltar'},
                {key: 'GR', value: 'Greece'},
                {key: 'GL', value: 'Greenland'},
                {key: 'GD', value: 'Grenada'},
                {key: 'GP', value: 'Guadeloupe'},
                {key: 'GU', value: 'Guam'},
                {key: 'GT', value: 'Guatemala'},
                {key: 'GG', value: 'Guernsey'},
                {key: 'GN', value: 'Guinea'},
                {key: 'GW', value: 'Guinea-Bissau'},
                {key: 'GY', value: 'Guyana'},
                {key: 'HT', value: 'Haiti'},
                {key: 'HM', value: 'Heard Island and McDonald Islands'},
                {key: 'VA', value: 'Holy See (Vatican City State)'},
                {key: 'HN', value: 'Honduras'},
                {key: 'HK', value: 'Hong Kong'},
                {key: 'HU', value: 'Hungary'},
                {key: 'IS', value: 'Iceland'},
                {key: 'IN', value: 'India'},
                {key: 'ID', value: 'Indonesia'},
                {key: 'IR', value: 'Iran, Islamic Republic of'},
                {key: 'IQ', value: 'Iraq'},
                {key: 'IE', value: 'Ireland'},
                {key: 'IM', value: 'Isle of Man'},
                {key: 'IL', value: 'Israel'},
                {key: 'IT', value: 'Italy'},
                {key: 'JM', value: 'Jamaica'},
                {key: 'JP', value: 'Japan'},
                {key: 'JE', value: 'Jersey'},
                {key: 'JO', value: 'Jordan'},
                {key: 'KZ', value: 'Kazakhstan'},
                {key: 'KE', value: 'Kenya'},
                {key: 'KI', value: 'Kiribati'},
                {key: 'KP', value: "Korea, Democratic People's Republic of"},
                {key: 'KR', value: 'Korea, Republic of'},
                {key: 'KW', value: 'Kuwait'},
                {key: 'KG', value: 'Kyrgyzstan'},
                {key: 'LA', value: "Lao People's Democratic Republic"},
                {key: 'LV', value: 'Latvia'},
                {key: 'LB', value: 'Lebanon'},
                {key: 'LS', value: 'Lesotho'},
                {key: 'LR', value: 'Liberia'},
                {key: 'LY', value: 'Libya'},
                {key: 'LI', value: 'Liechtenstein'},
                {key: 'LT', value: 'Lithuania'},
                {key: 'LU', value: 'Luxembourg'},
                {key: 'MO', value: 'Macao'},
                {key: 'MK', value: 'Macedonia, the Former Yugoslav Republic of'},
                {key: 'MG', value: 'Madagascar'},
                {key: 'MW', value: 'Malawi'},
                {key: 'MY', value: 'Malaysia'},
                {key: 'MV', value: 'Maldives'},
                {key: 'ML', value: 'Mali'},
                {key: 'MT', value: 'Malta'},
                {key: 'MH', value: 'Marshall Islands'},
                {key: 'MQ', value: 'Martinique'},
                {key: 'MR', value: 'Mauritania'},
                {key: 'MU', value: 'Mauritius'},
                {key: 'YT', value: 'Mayotte'},
                {key: 'MX', value: 'Mexico'},
                {key: 'FM', value: 'Micronesia, Federated States of'},
                {key: 'MD', value: 'Moldova, Republic of'},
                {key: 'MC', value: 'Monaco'},
                {key: 'MN', value: 'Mongolia'},
                {key: 'ME', value: 'Montenegro'},
                {key: 'MS', value: 'Montserrat'},
                {key: 'MA', value: 'Morocco'},
                {key: 'MZ', value: 'Mozambique'},
                {key: 'MM', value: 'Myanmar'},
                {key: 'NA', value: 'Namibia'},
                {key: 'NR', value: 'Nauru'},
                {key: 'NP', value: 'Nepal'},
                {key: 'NL', value: 'Netherlands'},
                {key: 'NC', value: 'New Caledonia'},
                {key: 'NZ', value: 'New Zealand'},
                {key: 'NI', value: 'Nicaragua'},
                {key: 'NE', value: 'Niger'},
                {key: 'NG', value: 'Nigeria'},
                {key: 'NU', value: 'Niue'},
                {key: 'NF', value: 'Norfolk Island'},
                {key: 'MP', value: 'Northern Mariana Islands'},
                {key: 'NO', value: 'Norway'},
                {key: 'OM', value: 'Oman'},
                {key: 'PK', value: 'Pakistan'},
                {key: 'PW', value: 'Palau'},
                {key: 'PS', value: 'Palestine, State of'},
                {key: 'PA', value: 'Panama'},
                {key: 'PG', value: 'Papua New Guinea'},
                {key: 'PY', value: 'Paraguay'},
                {key: 'PE', value: 'Peru'},
                {key: 'PH', value: 'Philippines'},
                {key: 'PN', value: 'Pitcairn'},
                {key: 'PL', value: 'Poland'},
                {key: 'PT', value: 'Portugal'},
                {key: 'PR', value: 'Puerto Rico'},
                {key: 'QA', value: 'Qatar'},
                {key: 'RE', value: 'RÃ©union'},
                {key: 'RO', value: 'Romania'},
                {key: 'RU', value: 'Russian Federation'},
                {key: 'RW', value: 'Rwanda'},
                {key: 'BL', value: 'Saint Barthelemy'},
                {key: 'SH', value: 'Saint Helena, Ascension and Tristan da Cunha'},
                {key: 'KN', value: 'Saint Kitts and Nevis'},
                {key: 'LC', value: 'Saint Lucia'},
                {key: 'MF', value: 'Saint Martin (French part)'},
                {key: 'PM', value: 'Saint Pierre and Miquelon'},
                {key: 'VC', value: 'Saint Vincent and the Grenadines'},
                {key: 'WS', value: 'Samoa'},
                {key: 'SM', value: 'San Marino'},
                {key: 'ST', value: 'Sao Tome and Principe'},
                {key: 'SA', value: 'Saudi Arabia'},
                {key: 'SN', value: 'Senegal'},
                {key: 'RS', value: 'Serbia'},
                {key: 'SC', value: 'Seychelles'},
                {key: 'SL', value: 'Sierra Leone'},
                {key: 'SG', value: 'Singapore'},
                {key: 'SX', value: 'Sint Maarten (Dutch part)'},
                {key: 'SK', value: 'Slovakia'},
                {key: 'SI', value: 'Slovenia'},
                {key: 'SB', value: 'Solomon Islands'},
                {key: 'SO', value: 'Somalia'},
                {key: 'ZA', value: 'South Africa'},
                {key: 'GS', value: 'South Georgia and the South Sandwich Islands'},
                {key: 'SS', value: 'South Sudan'},
                {key: 'ES', value: 'Spain'},
                {key: 'LK', value: 'Sri Lanka'},
                {key: 'SD', value: 'Sudan'},
                {key: 'SR', value: 'Suriname'},
                {key: 'SJ', value: 'Svalbard and Jan Mayen'},
                {key: 'SZ', value: 'Swaziland'},
                {key: 'SE', value: 'Sweden'},
                {key: 'CH', value: 'Switzerland'},
                {key: 'SY', value: 'Syrian Arab Republic'},
                {key: 'TW', value: 'Taiwan, Province of China'},
                {key: 'TJ', value: 'Tajikistan'},
                {key: 'TZ', value: 'Tanzania, United Republic of'},
                {key: 'TH', value: 'Thailand'},
                {key: 'TL', value: 'Timor-Leste'},
                {key: 'TG', value: 'Togo'},
                {key: 'TK', value: 'Tokelau'},
                {key: 'TO', value: 'Tonga'},
                {key: 'TT', value: 'Trinidad and Tobago'},
                {key: 'TN', value: 'Tunisia'},
                {key: 'TR', value: 'Turkey'},
                {key: 'TM', value: 'Turkmenistan'},
                {key: 'TC', value: 'Turks and Caicos Islands'},
                {key: 'TV', value: 'Tuvalu'},
                {key: 'UG', value: 'Uganda'},
                {key: 'UA', value: 'Ukraine'},
                {key: 'AE', value: 'United Arab Emirates'},
                {key: 'GB', value: 'United Kingdom'},
                {key: 'US', value: 'United States'},
                {key: 'UM', value: 'United States Minor Outlying Islands'},
                {key: 'UY', value: 'Uruguay'},
                {key: 'UZ', value: 'Uzbekistan'},
                {key: 'VU', value: 'Vanuatu'},
                {key: 'VE', value: 'Venezuela, Bolivarian Republic of'},
                {key: 'VN', value: 'Viet Nam'},
                {key: 'VG', value: 'Virgin Islands, British'},
                {key: 'VI', value: 'Virgin Islands, U.S.'},
                {key: 'WF', value: 'Wallis and Futuna'},
                {key: 'EH', value: 'Western Sahara'},
                {key: 'YE', value: 'Yemen'},
                {key: 'ZM', value: 'Zambia'},
                {key: 'ZW', value: 'Zimbabwe'}
            ];
            for (var c in countries) {
                if (countries[c]['key'].length > 0) {
                    countries[c]['display'] = countries[c]['key'] + " (" + countries[c]['value'] + ")";
                } else {
                    countries[c]['display'] = "";
                }
            }
            return countries;
        }

        function languages() {
            return [
                {key: '', value: ''},
                {key: 'SIGN', value: 'Sign'},
                {key: 'ARABI', value: 'Arabi'},
                {key: 'BENGALI', value: 'Bengali'},
                {key: 'ENGLISH', value: 'English'},
                {key: 'FRENCH', value: 'French'},
                {key: 'GERMAN', value: 'German'},
                {key: 'HAUSA', value: 'Hausa'},
                {key: 'HINDUSTANI', value: 'Hindustani (Hindi/Urdu)'},
                {key: 'ITALIAN', value: 'Italian'},
                {key: 'JAPANESE', value: 'Japanese'},
                {key: 'JAVANESE', value: 'Javanese'},
                {key: 'KOREAN', value: 'Korean'},
                {key: 'MALAY', value: 'Malay (incl. Indonesian and Malaysian)'},
                {key: 'MANDARIN', value: 'Mandarin Chinese (incl. Standard Chinese)'},
                {key: 'MARATHI', value: 'Marathi'},
                {key: 'PERSIAN', value: 'Persian'},
                {key: 'PORTUGUESE', value: 'Portuguese'},
                {key: 'PUNJABI', value: 'Punjabi'},
                {key: 'RUSSIAN', value: 'Russian'},
                {key: 'SPANISH', value: 'Spanish'},
                {key: 'SWAHILI', value: 'Swahili'},
                {key: 'TAMIL', value: 'Tamil'},
                {key: 'TELUGU', value: 'Telugu'},
                {key: 'TURKISH', value: 'Turkish'},
                {key: 'VIETNAMESE', value: 'Vietnamese'},
                {key: 'WU_CHINESE', value: 'Wu Chinese (incl. Shanghainese)'},
                {key: 'YUE_CHINESE', value: 'Yue Chinese (incl. Cantonese)'}
            ];
        }

    }

})();

(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('FliPersonHomeController', FliPersonHomeController);

    FliPersonHomeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'DateUtils', 'entity', 'FliPerson'];

    function FliPersonHomeController($scope, $rootScope, $state, $stateParams, previousState, DateUtils, entity, FliPerson) {
        var vm = this;

        console.log("entity", entity);
        console.log("previousState", previousState);
        console.log("$stateParams", $stateParams);

        // vm.centreId = $stateParams.centreId;

        vm.previous = previous;
        vm.person = entity;
        vm.previousState = previousState.name;
        vm.dateformat = DateUtils.dateformat();

        var unsubscribe = $rootScope.$on('fliApp:personUpdate', function (event, result) {
            vm.person = result;
            console.log("fliApp:personUpdate", result);
        });
        $scope.$on('$destroy', unsubscribe);

        function previous(centreId) {
            console.log('previous', centreId);
            $state.go('fli-person', centreId); // {centreId: vm.centreId});
        }

    }
})();

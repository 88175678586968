(function () {
    'use strict';

    angular
        .module('fliApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal($q, Account) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            user: user,
            centre: centre,
            centreId: centreId,
            hasCentre: hasCentre,
            centres: centres,
            preferences: preferences,
            preferredSessionType: preferredSessionType,
            preferredCentreId: preferredCentreId,
            preferredDateFormat: preferredDateFormat,
            preferredLastApplication: preferredLastApplication,
            preferredLastVersion: preferredLastVersion,
            preferredSupportedHearingType: preferredSupportedHearingType,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;

        function user() {
            // console.log("user", _identity);
            return _identity ? _identity.firstName : null;
            // return _identity ? _identity.login : null;
        }

        function hasCentre() {
            return centre() != null;
        }

        function centre() {
            // console.log("centre", _identity);
            if (_identity && _identity.userCentre) {
                return _identity.userCentre.centreName;
            } else {
                return null;
            }
        }

        function centreId() {
            // console.log("centre", _identity);
            if (_identity && _identity.userCentre) {
                // console.log("centre", _identity.userCentre);
                return _identity.userCentre.centreId;
            } else {
                return null;
            }
        }

        function centres() {
            // console.log("centres", _identity);
            if (_identity && _identity.userCentres) {
                return _identity.userCentres;
            } else {
                return null;
            }
        }

        function preferences() {
            // console.log("preferences", _identity);
            if (_identity && _identity.preferences) {
                return _identity.preferences;
            } else {
                return null;
            }
        }

        function preferredSessionType() {
            return preference('PREFERRED_SESSION_TYPE');
        }

        function preferredCentreId() {
            return preference('PREFERRED_CENTRE_ID');
        }

        function preferredDateFormat() {
            return preference('PREFERRED_DATE_FORMAT');
        }

        function preferredLastApplication() {
            return preference('PREFERRED_LAST_APPLICATION');
        }

        function preferredLastVersion() {
            return preference('PREFERRED_LAST_VERSION');
        }

        function preferredSupportedHearingType() {
            return preference('PREFERRED_SUPPORTED_HEARING_TYPE');
        }

        function preference(preference) {
            console.log("preference", preference);
            if (_identity && _identity.preferences) {
                console.log("_identity.preferences", _identity.preferences);
                for (var i = 0; i < _identity.preferences.length; i++) {
                    var pref = _identity.preferences[i];
                    console.log("pref", pref.key);
                    if (pref.key === preference) {
                        return pref.value;
                    }
                }

            } else {
                return null;
            }
        }

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function () {
                return false;
            });
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account.data;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }
    }
})();

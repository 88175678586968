(function () {
    'use strict';

    angular
        .module('fliApp')
        .controller('ExportController', ExportController);

    ExportController.$inject = ['Auth', 'Principal', 'CentreSessions', '$q'];

    function ExportController(Auth, Principal, CentreSessions, $q) {
        var vm = this;

        console.log("ExportController");

        vm.exportFli = exportFli;
        vm.error = null;
        vm.success = null;
        vm.centre = Principal.centre;
        vm.centreId = Principal.centreId;
        console.log("ExportController", vm.centreId());

        vm.testDataResults = [];
        vm.exportFilename = exportFilename;
        vm.testDataHeaders = testDataHeaders;
        vm.gettestDataResults = gettestDataResults;


        Principal.identity().then(function (account) {
            vm.account = account;
        });

        function exportFli() {
            console.log("TODO: exportFli");
        }

        function exportFilename() {
            var now = moment().format('YYYY-MM-DD.HH-mm-ss');
            console.log("exportFilename", now);
            return "fli." + now + ".csv";
        }

        function testDataHeaders() {
            console.log("testDataHeaders");

            var headings = [];
            headings.push("Centre");
            headings.push("FliKey");
            headings.push("Child");
            // headings.push("Product");
            // headings.push("Operation");
            // headings.push("Test");
            // headings.push("Result");
            // headings.push("Value");
            // headings.push("High");
            // headings.push("Low");
            // headings.push("UOM");
            // headings.push("Personnel");
            // headings.push("Username");
            // headings.push("Date");
            // headings.push("Client");
            // headings.push("Client Instance");
            // headings.push("Test Spec");
            //return [
            //    //,
            //    "Src",
            //    "Serial",
            //    "Product",
            //    "Operation",
            //    "Test",
            //    "Result",
            //    "Value",
            //    "High",
            //    "Low",
            //    "UOM",
            //    "Personnel",
            //    "Username",
            //    "Date",
            //    "Client",
            //    "Client Instance",
            //    "Test Spec"
            //];
            return headings;
        }

        function gettestDataResults() {
            console.log("gettestDataResults");

            var deferred = $q.defer();

            var result = CentreSessions.query({centreId: vm.centreId()}, onSuccess, onError);
            console.log("gettestDataResults:", result);

            function onSuccess(data, headers) {
                console.log("onSuccess:", data);
                deferred.resolve(data);
            }

            function onError(error) {
                console.log("onError:", error);
                AlertService.error(error.data.message);
            }

            return deferred.promise;

            // var csv = [];
            // for (var i = 0; i < vm.testDataResults.length; i++) {
            //     var data = vm.testDataResults[i];
            //
            //     var rec = [];
            //     //rec.push(data.testDetailId);
            //     rec.push(data.serialNumber);
            //     rec.push(data.partNumber);
            //     rec.push(data.operation);
            //     rec.push(data.testName);
            //     rec.push(data.result);
            //     rec.push(data.value);
            //     rec.push(data.high);
            //     rec.push(data.low);
            //     rec.push(data.uom);
            //     rec.push(data.fullName);
            //     rec.push(data.username);
            //     rec.push(data.date);
            //     rec.push(data.testSystem);
            //     rec.push(data.testSystemInstance);
            //     rec.push(data.testSpecification);
            //     csv.push(rec);
            // }
            //
            // return [{a: 1, b: 2}, {a: 3, b: 4}];
            // return csv;
        }


    }
})();

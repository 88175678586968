(function() {
    'use strict';

    angular
        .module('fliApp')
        .controller('CentreDetailController', CentreDetailController);

    CentreDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Centre'];

    function CentreDetailController($scope, $rootScope, $stateParams, previousState, entity, Centre) {
        var vm = this;

        vm.centre = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('fliApp:centreUpdate', function(event, result) {
            vm.centre = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
